<template>
    <div id="transaction" class="transactiontable-body-wrapper">
        <div class="transaction-information text-lg font-bold mb-1 flex justify-between aaa" v-if="table.id">
            <div @click="$refs.modalTableRename.open()">
                {{$t('translations.components.transaction-table.table')}}:
                <span class="mr-1" v-if="table.name_temporary">{{ table.name_temporary }} - </span>
                {{ table.name }}

                <span v-if="table.activated_at" class="tag-green">{{ formattedTime(table.activated_at) }}</span>
            </div>

            <div class="" v-if="table.amount">
                <currency/>{{ vueNumberFormat(table.amount) }}
            </div>
            <modal-table-rename ref="modalTableRename" />
        </div>
        <div class="transactiontable-body scrollbar-hide" v-if="table.id">
            <!-- transaction -->
            <div v-if="table?.lines">
                <div v-if="table.lines.length > 0" class="transactiontable-content overflow-auto flex flex-col-reverse">
                    <div class="transaction-line grid grid-cols-12 py-1 px-1 flex items-start" :class="{'selected' : line.selected}" v-for="(line, index) in table.lines.slice().reverse()" :key="index" @click="lineSelect(line)">
                        <div class="col-span-1 flex justify-end">
                            <div class="leading-5">{{ parseInt(line.attributes.quantity) }}</div>
                        </div>
                        <div class="col-span-8 text-left pl-3">
                            <div class="font-bold leading-5 truncate">{{ line.attributes.description }}</div>
                            <div class="italic text-xs ml-3" v-if="line.attributes.notes">
                                {{ line.attributes.notes }}
                            </div>
                            <div class="italic text-xs ml-3" v-if="line.attributes.cardnumber">
                                {{ line.attributes.cardnumber }}
                            </div>

                            <div class="italic text-xs" v-for="(kitchen_grocery, grocery_index) in line.attributes.kitchen_groceries" v-bind:key="grocery_index">
                                {{ grocery_index }}: {{ kitchen_grocery }}
                            </div>
                            <div class="italic text-xs ml-3" v-if="line.attributes.variant_label">
                                {{ line.attributes.variant_label }}
                            </div>
                        </div>

                        <div class="col-span-3 flex items-center justify-end font-bold">
                            <div class="flex items-center justify-start">
                                <div>{{ vueNumberFormat(line.attributes.total) }}</div>
                                <div class="text-xs text-gray-300" v-if="line.attributes.discount_amount > 0">(-{{ vueNumberFormat(line.attributes.discount_amount) }})</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- modals -->
    <modal-notes ref="modalNotes" />

</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment';

// components

// modals
import ModalTableRename from '../components/ModalTableRename';
import ModalNotes from '../components/ModalNotes';

export default {
    props: ['layout'],
    name: 'Transaction',
    components: {
        ModalTableRename,
        ModalNotes,
    },
    mounted() {
        // console.log('transaction', this.transaction);

        console.log(this.table);
    },
    methods: {
        ...mapActions({
            // remove: 'transaction/removeItem',
            addQuantity: 'transaction/addQuantity',
            subQuantity: 'transaction/subQuantity',
            lineSelect: 'transaction/lineSelect',
        }),

        formattedTime(time) {
            return moment(time).format('HH:mm');
        },


        // lineSelect(line) {
        //     console.log(line)
        // },
    },
	computed: {
        total() {
            return parseFloat(this.transaction.total) + (this.table.amount ? parseFloat(this.table.amount) : 0)
        },
        transaction () {
            return this.$store.getters['transaction/transaction'];
        },
		table() {
			return this.$store.getters['transaction/table'];
        },
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
    }
}
</script>
