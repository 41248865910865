<template>
    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal"
                 class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">
                    <modal-close @close="close()" />
                    <div class="">
                        <div class="flex items-center justify-between mb-4 w-full pr-12">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl mb-0" id="modal-headline">
                                {{ reservation[0]?.name }}
                            </h3>
                            <h4 class="font-bold text-xl">
                                {{ processedTimeslot(timeslots) }}
                            </h4>
                        </div>

                        <div class="reservation-info">
                            <div class="info-elem">
                                <span class="info-elem-title">Naam:</span>
                                <span class="info-elem-description">{{reservation[0]?.name }}</span>
                            </div>
                            <div class="info-elem">
                                <span class="info-elem-title">E-mail:</span>
                                <span class="info-elem-description"> {{reservation[0]?.email }}</span>
                             </div>
                            <div class="info-elem">
                                <span class="info-elem-title">Telefoon:</span>
                                <span class="info-elem-description"> {{reservation[0]?.phone_number }}</span>
                            </div>
                            <div class="info-elem">
                                <span class="info-elem-title">Tafel: </span>
                                <span class="info-elem-description">{{reservation[0]?.table_name }}</span>
                            </div>
                        </div>

                        <div class="reservation-button-flexer flex-wrap" v-if="!beforeToday">
                            <button 
                                class="button button-large button-danger flex-grow " @click="modal_cancel = true">
                                Cancel reservation
                            </button>

                            <button class="button button-large button-filled flex-grow" 
                                v-if="reservation[0]?.table_id"
                                @click="bindTable(reservation[0]?.table_id)"
                                :class="{'disabled': reservation[0].status == 'assigned_to_table'}"
                            >

                                Tafel toekennen
                            </button>

                             <button class="button button-large button-ghost flex-grow" 
                                @click="modal_pick_table = true;"
                                v-else
                            >
                                Tafel kiezen
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fixed z-10 inset-0 overflow-y-auto" v-if="modal_pick_table">
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div class="fixed inset-0 transition-opacity" @click="modal_canel = false">
                        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                    <div id="modal"
                        class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div class="modal-content">
                                <div @click="modal_pick_table = false">
                                    <modal-close />
                                </div>
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl mb-8" id="modal-headline">
                                Kies tafel
                            </h3>

                            <div
                                class="default-input table w-full"
                                v-if="tables.data?.length"
                            >
                                <div class="mt-1 mb-4">
                                    <select
                                        @change="setTable($event)"
                                        name="table"
                                        class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent select-table"
                                    >
                                        <option></option>
                                        <option v-for="(table, index) in tables.data" :value="table.id" :key="index" :disabled="table.available == false">
                                            {{ table.name }} <span v-if="!table.available"> --- Niet beschikbaar</span>
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="reservation-button-flexer">
                                <button class="button button-large button-danger flex-grow" @click="modal_pick_table = false">
                                    Annuleren
                                </button>

                                <button 
                                    :class="{'disabled': !picked_table_id}"
                                    class="button button-large button-filled flex-grow" @click="bindTable(this.picked_table_id)">
                                    Kies tafel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fixed z-10 inset-0 overflow-y-auto" v-if="modal_cancel">
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div class="fixed inset-0 transition-opacity" @click="modal_canel = false">
                        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                    <div id="modal"
                        class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div class="modal-content">
                                <div @click="modal_cancel = false">
                                    <modal-close />
                                </div>
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl mb-8" id="modal-headline">
                                Cancel reservation?
                            </h3>

                            <div class="mb-5 text-lg">Are you sure you want to cancel this reservation?</div>

                            <div class="reservation-button-flexer">
                                <button 

                                    class="button button-large button-danger flex-grow">
                                    Yes
                                </button>

                                <button class="button button-large button-filled flex-grow" @click="modal_cancel = false">
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import ModalClose from "./ModalClose";
import moment from 'moment';

export default {
    name: 'ModalReservationDetails',
    props: ['reservation', 'timeslots'],
    emits: ['cancelled', 'bound'],
    components: {
        ModalClose,
    },
    data () {
        return {
            active: false,
            modal_cancel: false,

            modal_pick_table: false,
            formattedTimeslots: [],
            tables: [],
            picked_table_id: null,
            beforeToday: false,
        };
    },
   
    watch: {
        timeslots: function () {
            this.beforeToday = moment(moment(this.timeslots[0].date).format('YYYY-MM-DD')).isBefore(moment(), 'day');
        },

        modal_pick_table: function () {
            if (this.modal_pick_table == true) {
                this.findTables();
                this.setFormattedTimeslot();
            }
        }
    },

    methods: {
        open () {
            this.active = true;
        },

        close () {
            this.active = false;

            this.modal_cancel = false;
            this.modal_pick_table = false;
        },

        setTable(evt) {
            this.picked_table_id = evt.target.value;
        },

        // Show starting time of first timeslotbooking and ending time of last timeslot booking
        processedTimeslot(reservation) {
            let last_slot = reservation[reservation.length - 1].timeslot.split(' ');

            return reservation[0].timeslot.split(' ')[0] + ' - ' + last_slot[last_slot.length - 1];
        },


        setFormattedTimeslot() {
            let timeslots = [];
            this.timeslots.forEach(function(elem) {
                timeslots.push(elem.timeslot);
            });

            this.formattedTimeslots = timeslots;
        },

        async findTables() {
            if (this.reservation) {
                let area_id = this.reservation[0].area_id;

                await axios.post('reservations/areas/tables', {
                    area_id: area_id,
                    timeslots: this.formattedTimeslots,
                    date: this.timeslots[0].date,
                }).then(response => {
                    this.tables = response.data;
                });
            }
        },

        async bindTable(table_id) {
            await axios.post('/reservation/bind-table', {
                reservation_id: this.reservation[0].id,
                table_id: table_id
            }).then(response => {

                console.log(response);

                this.close(); 
                this.$emit('bound');

                if (response.data.result == 'failed') {
                    this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Er staat al een reservering op deze tafel." }, 2000);
                }
            }).catch(error => {
                console.log(error);
            })
        },

        async reservationCancel() {
            console.log(this.reservation);

            await axios.post('/reservation/cancel', {
                    reservation_id: this.reservation[0].id,
                }).then(response => {
                    console.log(response)
                    this.$emit('cancelled');
                    this.active = false;

                if (response.data.result == 'success') {
                    this.$notify({ group: "notifications", type: 'success', title: "Reservering geannuleerd"}, 2000);
                }

                }).catch(error => {
                    this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                    console.error(error)
                })
        }

        // todo. assign reservation to table. On other button
    },

    // computed: {
    //     tablesAvailable(tables) {
    //         return tables?.filter((table) => table.available == true).length;
    //     },
    // }
};
</script>
