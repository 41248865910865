<template>
    <div v-if="!isMobile" class="button-anchor">
        <a class="w-full flex justify-center cursor-pointer" @click="open()">
            {{$t('translations.components.modal-table-history.title')}}
        </a>
    </div>
    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal" class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">

                  <modal-close @close="close()"/>

                    <div class="sm:flex sm:items-start mb-5">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                {{$t('translations.components.modal-table-history.table')}}: {{ table.attributes.name }}
                            </h3>
                        </div>
                    </div>

                    <div v-if="transactions.length == 0" style="height:350px">
                        {{$t('translations.components.modal-table-history.no-transaction')}}
                    </div>

                    <div v-else>
                        <div class="overflow-y-auto scrollbar-hide" style="height:350px">
                            <div class="border-b py-1 px-2 grid grid-cols-12 mt-1 text-xs">
                                <div class="col-span-2 font-bold">{{$t('translations.components.modal-table-history.number-of')}}</div>
                                <div class="col-span-6 font-bold">{{$t('translations.components.modal-table-history.description')}}</div>
                                <div class="col-span-2 font-bold">{{$t('translations.components.modal-table-history.time-date')}}</div>
                                <div class="col-span-2 font-bold text-right">{{$t('translations.components.modal-table-history.total')}}</div>
                            </div>
                            <div v-for="(transaction, index) in transactions" :key="index" class="py-1 px-2 mt-1 text-xs">
                                <div class="grid grid-cols-12">
                                    <div class="col-span-2">
                                        <span class="font-bold">{{ transaction.attributes.transactionnumber }}</span>
                                        <span class="ml-1">({{ transaction.attributes.source }})</span>
                                    </div>
                                     <div class="col-span-6 font-bold text-underline"
                                            v-if="transaction.attributes.table_name && transaction.attributes.table_name !== table.attributes.name"
                                        >
                                            {{$t('translations.components.modal-table-history.table')}}: {{ transaction.attributes.table_name }}
                                    </div>
                                    <div v-else class="col-span-6"></div>
                                    <div class="col-span-2">{{ transaction.attributes.time }} {{ transaction.attributes.date }}</div>
                                    <div class="col-span-2 text-right font-bold">{{ vueNumberFormat(transaction.attributes.total) }}</div>
                                </div>
                                <div v-for="(line, index_line) in transaction.relationships.lines" :key="index_line" class="py-1 grid grid-cols-12 mt-1 text-xs">
                                    <div class="col-span-2 pl-5">{{ parseFloat(line.attributes.quantity) }}</div>
                                    <div class="col-span-8">{{ line.attributes.description }}</div>
                                    <div class="col-span-2 text-right">{{ vueNumberFormat(line.attributes.total) }}</div>
                                </div>
                                <!-- <div class="col-span-6">{{ line.attributes.description }}</div> -->
                                <!-- <div class="col-span-2">{{ line.attributes.formatted_created_at }}</div> -->
                                <!-- <div class="col-span-2 text-right">{{ vueNumberFormat(line.attributes.total) }}</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import ModalClose from './ModalClose.vue';

// print

export default {
    components: {
      ModalClose,
    },
    data () {
        return {
            active: false,
            table: null,
            transactions: [],
        }
    },
    methods: {
        open() {
            this.tableTransactionsGet();
        },

        close() {
            this.active = false
        },

        async tableTransactionsGet() {

            axios.get('/tables/' + this.transaction.table_id + '/transactions').then(response => {
                this.table = response.data.data.table
                this.transactions = response.data.data.transactions
                console.log(this.transactions);
            }).catch(() => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
            }).finally(() => {
                this.active = true;
            })
        },
    },
	computed: {
		transaction() {
			return this.$store.getters['transaction/transaction'];
        },
    }
}
</script>
