<template>
    <div v-if="view == 'button'" @click="open()" class="col-span-1 button-anchor">
        <div class="flex justify-center">
            ...
        </div>
    </div>

    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal"
                 class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">
                    <modal-close @close="close()" />
                    <div class="flex justify-between items-center">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                {{$t('translations.components.modal-functions.title')}}
                            </h3>
                        </div>
                    </div>

                    <div class="mt-2 overflow-x-auto">
                        <div @click="$refs.booking.open()"
                             class="w-full button button-blur button-large flex items-center justify-center p-6 space-x-6 truncate mt-4">
                            <div class="flex justify-center">
                                {{$t('translations.components.modal-functions.bookings')}}
                            </div>
                        </div>
                        <div @click="$refs.walletcheckcredit.open()"
                             class="w-full button button-blur button-large flex items-center justify-center p-6 space-x-6 truncate mt-4">
                            <div class="flex justify-center">
                                {{$t('translations.components.modal-functions.card-balance')}}?
                            </div>
                        </div>
                        <div @click="$refs.walletaddcredit.open()"
                             class="w-full button button-blur button-large flex items-center justify-center p-6 space-x-6 truncate mt-4">
                            <div class="flex justify-center">
                                {{$t('translations.components.modal-functions.top-up-card')}}
                            </div>
                        </div>
                        <div @click="$refs.preordercollect.open()"
                             class="w-full button button-blur button-large flex items-center justify-center p-6 space-x-6 truncate mt-4">
                            <div class="flex justify-center">
                                {{$t('translations.components.modal-functions.pick-up-preorder')}}
                            </div>
                        </div>
                        <div @click="$refs.customers.open()"
                             class="w-full button button-blur button-large flex items-center justify-center p-6 space-x-6 truncate mt-4">
                            <div class="flex justify-center">
                                {{$t('translations.components.modal-functions.customers')}}
                            </div>
                        </div>
                        <div @click="$refs.reservations.open()"
                             class="w-full button button-blur button-large flex items-center justify-center p-6 space-x-6 truncate mt-4">
                            <div class="flex justify-center">
                                {{$t('translations.components.modal-functions.reservations')}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <modal-booking ref="booking" @opened="close()" />
    <modal-preorder-collect ref="preordercollect" @opened="close()" :allow-scanner="true" />
    <modal-reservations ref="reservations" @opened="close()" :allow-scanner="true" />
    <modal-wallet-add-credit ref="walletaddcredit" @opened="close()" :allow-scanner="true" />
    <modal-wallet-check-credit ref="walletcheckcredit" @opened="close()" :allow-scanner="true" />
    <modal-customers ref="customers" @opened="close()" @close="close()" :allow-scanner="true" />
</template>

<script>
import ModalBooking from './ModalBooking';
import ModalPreorderCollect from './ModalPreorderCollect.vue';
import ModalWalletAddCredit from './ModalWalletAddCredit.vue';
import ModalReservations from './ModalReservations.vue';
import ModalWalletCheckCredit from './ModalWalletCheckCredit.vue';
import ModalCustomers from "./ModalCustomers";
import ModalClose from './ModalClose.vue';

export default {
    props: ['view', 'table'],
    emits: ['selected'],
    components: {
        ModalBooking,
        ModalClose,
        ModalPreorderCollect,
        ModalWalletAddCredit,
        ModalWalletCheckCredit,
        ModalCustomers,
        ModalReservations,
    },
    data () {
        return {
            active: false,
            bookings: [],
        };
    },

    mounted() {
        console.log('table', this.table);
    },

    methods: {
        open () {
            this.active = true;
        },

        close () {
            this.active = false;
        },
    },
};
</script>
