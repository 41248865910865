<template>
        <item ref="item" initialquantity="1" />
</template>

<script>
import axios from 'axios'

// mixins
import BarcodeReader from '../mixins/barcode.reader.js';

// components
import Item from '../components/Item';

export default {
    name: 'BarcodeScanner',
    mixins: [BarcodeReader],
    components: {
        Item,
    },
    data() {
        return {
            item: null,
        }
    },
    methods: {
        // search item on barcode scanner
        async barcodeScanned(barcode) {

            // only allow the scan for product when a modal with numpad isn't active
            if (this.modalActive === false) {

                if (barcode.length > 0) {
                    // todo loading spinner
                    await axios.post('/items/search-barcode', {
                        barcode: barcode
                    }).then(response => {
                        if (response.data.data) {
                            this.item = response.data.data
                            this.$refs.item.addItemByScannedBarcode(this.item)
                        }
                    }).catch(error => {
                        if (error.response.status == 404) {
                            this.$notify({ group: "notifications", type: 'error', title: "Niet gevonden", text: "Gescande artikel is niet gevonden" }, 2000);
                        } else {
                            this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                            console.error(error)
                        }
                    })
                }
            }
        },
    },
    computed: {
		transaction() {
			return this.$store.getters['transaction/transaction'];
        },
        modalActive () {
            return this.$store.getters['terminal/modalActive'];
        },
    }
}
</script>
