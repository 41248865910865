<template>
   <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" @click="close()">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div id="modal"
                     class="reservation-modal modal-extra-large inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                     role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                >
                <modal-close @close="close()"/>

                <div class="modal-content">
                    <div class="mb-5">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left flex items-center justify-between pr-12 w-full">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl mr-8" id="modal-headline">
                                Reservations
                            </h3>

                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl">{{selectedDate}}</h3>
                        </div>
                    </div>

                    <div class="title-button-bar">
                        <div class="dates">
                            <div class="button-bar-action" @click="this.selectedDate = this.today" :class="{'active' : selectedDate == today}">
                                Today
                            </div>

                            <div class="button-bar-action" @click="this.selectedDate = this.tomorrow" :class="{'active' : selectedDate == tomorrow}">
                                Tomorrow
                            </div>
                        </div>

                        <div class="button-bar-action" @click="$refs.reservationcreate.open()">
                            Create reservation

                            <span class="plus-icon">+</span>
                        </div>
                    </div>

                    <div class="reservations-picker-wrapper">
                        <div class="reservations scrollbar-hide mr-8">
                            <div class="reservation"
                                v-for="(timeslot, index) in reservations.data?.data.timeslots" :key="index" >

                                <div @click="openReservation(timeslot[0].reservation_id)" class="inner">
                                    <div>
                                        <h1>
                                            {{ getInfo(timeslot[0].reservation_id, 'name') }}
                                        </h1>
                                        <p class="mb-1">{{ processedTimeslot(timeslot) }}</p>
                                        <p class="mb-1"><strong>Tafel: - </strong>{{ getInfo(timeslot[0].reservation_id, 'table_name') }}</p>
                                        <p class="mb-1"><strong>Aantal personen:  </strong>{{getInfo(timeslot[0].reservation_id, 'resources')}}</p>
                                        <p class="" v-if="getInfo(timeslot[0].reservation_id, 'notes')">
                                            <strong>Notitie: - </strong>{{ getInfo(timeslot[0].reservation_id, 'notes') }}
                                        </p>
                                    </div>
                                    <div>
                                        <p v-if="getInfo(timeslot[0].reservation_id, 'status')" class="status"
                                        :class="getInfo(timeslot[0].reservation_id, 'status')"
                                        >
                                            Assigned
                                        </p>

                                        <p v-if="getInfo(timeslot[0].reservation_id, 'assigned_to_table_at')" class="status opened_at mt-2 italic text-right"
                                        >
                                            {{ formattedTime(getInfo(timeslot[0].reservation_id, 'assigned_to_table_at')) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="date pick-date" :class="{'active' : selectedDate != tomorrow && selectedDate != today}">
                            <date-picker class="date-picker"
                                v-model="selectedDate" color="gray"
                                is-dark
                                format="yyyy-MM-dd"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-reservation-details
            ref="reservationdetails"
            :reservation="activeReservation"
            :timeslots="activeTimeslots"
            @cancelled="resetModal()"
            @bound="resetModal()"
        />

        <modal-reservation-create
            ref="reservationcreate"
            :sentDate="selectedDate"
            @closed="resetModal()"
            @created="resetModal()"
        />
    </div>
</template>

<script>
import axios from 'axios'

import moment from 'moment';
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';


import ModalClose from "./ModalClose";
import ModalReservationDetails from "./ModalReservationDetails";
import ModalReservationCreate from "./ModalReservationCreate";

export default {
    name: 'ModalReservations',
    emits: ['opened'],
    components: {
        DatePicker,
        ModalClose,
        ModalReservationDetails,
        ModalReservationCreate,
    },
    data () {
        return {
            reservations: [],
            active: false,
            selectedDate: null,
            activeReservation: null,
            activeTimeslots: null,
            openDatePicker: false,
        }
    },

    // mounted() {
    //     this.constructDates();
    // },

    watch: {
        selectedDate: function () {
            let date = new Date(this.selectedDate);

            this.setDate(date.toISOString().slice(0, 10));
            this.openDatePicker = false;
        }
    },

    methods: {
        open() {
            this.constructDates();
            this.active = true,
            this.getReservations();
            this.$emit('opened')
        },

        resetModal() {
            let date = new Date();
            this.selectedDate = date.toISOString().slice(0, 10);
            this.getReservations();
        },

        close() {
            this.active = false
        },

        setDate(date) {
            this.selectedDate = date;

            this.getReservations();
        },

        formattedTime(time) {
            return moment(time).format('HH:mm');
        },

        // todo: make filters
         compareName( a, b ) {
            if ( a.name < b.name ){
                return -1;
            }
            if ( a.name > b.name ){
                return 1;
            }

            return 0;
        },

        orderByName() {
            console.log(this.reservations?.data.data.reservations.sort(this.compareName));
        },

        getInfo(reservation_id, data = false) {
            let reservation = this.reservations.data.data.reservations.filter(reservation => reservation['id'] == reservation_id);


            if (data) {
                return reservation[0][data];
            }

            return reservation
        },

        openReservation(reservation_id) {
            this.activeReservation = this.getInfo(reservation_id);
            this.activeTimeslots = this.reservations.data.data.timeslots[reservation_id];

            this.$refs.reservationdetails.open();
        },

        //get today and tomorrow, set selecteddate to today by default
        constructDates() {
            let date = new Date();

            let today = date.toISOString().slice(0, 10);
            let tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrow = tomorrow.toISOString().slice(0, 10);

            this.today = today;
            this.selectedDate = today
            this.tomorrow = tomorrow;
        },

        processedTimeslot(reservation) {
            let last_slot = reservation[reservation.length - 1].timeslot.split(' ');

            return reservation[0].timeslot.split(' ')[0] + ' - ' + last_slot[last_slot.length - 1];
        },

        async getReservations() {

           await axios.get('/reservations?date=' + this.selectedDate).then(response => {

                this.reservations = response;

            }).catch(error => {
                console.log(error);
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Opes er ging iets fout..." }, 2000);
            })
        },
    },
	computed: {
		transaction() {
			return this.$store.getters['transaction/transaction'];
        },
    }
}
</script>
