<template>
    <navigation-pos />

    <div id="pos" class="p-2" :class="terminal.buttonsize + ' ' + terminal.product_columns">
        <pos-default v-if="terminal.layout == 'default'" />
        <pos-events v-else-if="terminal.layout == 'events'" />
        <pos-retail v-else-if="terminal.layout == 'retail'" />
        <pos-table v-else-if="terminal.layout == 'table'" />
    </div>
</template>

<script>
// mixins
import PosBootstrapHelper from '../../mixins/pos-bootstrap.helper.js';

// components
import NavigationPos from '../../components/NavigationPos'
import PosDefault from './PosDefault'
import PosEvents from './PosEvents'
import PosTable from './PosTable'
import PosRetail from './PosRetail'

// modals
export default {
    name: 'Pos',
    mixins: [PosBootstrapHelper],
    components: {
        NavigationPos,
        PosDefault,
        PosEvents,
        PosTable,
        PosRetail,
    },
}
</script>
