<template>
    <div v-if="online" class="flex items-center text-xs">
        <div class="dot bg-green-500"></div>
        <div class="ml-2">{{$t('translations.components.status.online')}}</div>
    </div>
    <div v-else class="flex items-center text-xs">
        <div class="dot bg-red-500"></div>
        <div class="ml-2">{{$t('translations.components.status.offline')}}</div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            online: navigator.onLine
        };
    },
    mounted() {
        window.addEventListener("online", this.onchange);
        window.addEventListener("offline", this.onchange);
        this.onchange();
    },
    beforeUnmount() {
        window.removeEventListener("online", this.onchange);
        window.removeEventListener("offline", this.onchange);
    },
    methods: {
        onchange() {
            this.online = navigator.onLine;
        }
    }
}
</script>

<style scoped>
.dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
}
</style>
