<template>
    <div id="postable" class="pos-wrapper flex flex-no-wrap h-full postable" :class="{'inverted' : terminal.inverted}">

        <div class="overview flex flex-grow flex-col justify-start h-full">

            <!-- searchbar -->
            <searchbar :group="selected_group" @search="search($event)" @levelup="selectGroupById(groups, $event)" />

            <!-- retail view -->
            <div v-if="terminal.items_view == 'overview'"
                 :class="[terminal.title == 'bottom' ? 'categories-bottom' : 'categories-top']"
                 class="tile-area-wrapper flex-grow overflow-y-auto overflow-x-hidden flex-grow">
                <div class="overflow-y-auto overflow-x-hidden scrollbar-hide">
                    <div class="tile-area" v-if="groups">
                        <div @click="selectGroup(child)"
                             v-for="(child, index_child) in selected_group.children"
                             :key="index_child"
                             class="relative tile-outer group-child cursor-pointer p-1">

                            <div class="tile subcategory"
                                 :class="{'has-color': child.color || selected_group.color}"
                                 :style="[child.color ? {'border-color': child.color} : {'border-color': selected_group.color}]"
                            >

                                <!-- :style="{'background-color': group.color, 'border-color': group.color}"> -->
                                <div class="truncate font-semibold">{{ child.name }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tile-area" v-if="groups">
                        <item :initialitem="item" view="button"
                              :parentcolor="selected_group.color"
                              :tilesize="terminal.buttonsize" :initialquantity="quantity"
                              @itemadded="handleItemAdded()" @modalopened="modalopened" @modalclose="modalClosed"
                              v-for="(item, index_items) in pos_visible(selected_group.items)" :key="index_items" />
                        <!-- <div v-for="(item, index_items) in selected_group.items" :key="index_items"> -->
                        <!-- {{ item }} -->
                        <!-- </div> -->
                        <!-- {{selected_group.items}} -->
                    </div>
                </div>

                <div class="tile-area menu">
                    <div class="tile-outer menu" v-for="(group, index) in groups" :key="index">
                        <div @click="selectGroup(group)"
                             class="group menu-tile border flex items-center justify-center"
                             :class="{'active': selected_group.id == group.id || selected_group.parent_id == group.id }"
                             :style="{'border-color': group.color}">
                            <div class="truncate">{{ group.name }}</div>
                            <div class="active-border" :style="{'background-color': group.color}"></div>
                        </div>
                        <div class="menu-border"></div>

                    </div>

                    <!--                    <div class="">-->
                    <!--                        <div class="button button-blur button-icon">-->
                    <!--                            Scan-->
                    <!--                            <svg class="w-5 h-5" viewBox="0 0 24 24" fill="white"-->
                    <!--                                 xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path fill-rule="evenodd" clip-rule="evenodd"-->
                    <!--                                      d="M20.4615 4.82051C20.4615 4.11246 19.8875 3.53846 19.1795 3.53846L17.1282 3.53846C16.7034 3.53846 16.359 3.19407 16.359 2.76923C16.359 2.3444 16.7034 2 17.1282 2L19.1795 2C20.7372 2 22 3.26279 22 4.82051V6.8718C22 7.29663 21.6556 7.64103 21.2308 7.64103C20.8059 7.64103 20.4615 7.29663 20.4615 6.8718V4.82051Z"-->
                    <!--                                      fill="white" />-->
                    <!--                                <path fill-rule="evenodd" clip-rule="evenodd"-->
                    <!--                                      d="M20.4615 19.1795C20.4615 19.8875 19.8875 20.4615 19.1795 20.4615H17.1282C16.7034 20.4615 16.359 20.8059 16.359 21.2308C16.359 21.6556 16.7034 22 17.1282 22H19.1795C20.7372 22 22 20.7372 22 19.1795V17.1282C22 16.7034 21.6556 16.359 21.2308 16.359C20.8059 16.359 20.4615 16.7034 20.4615 17.1282V19.1795Z"-->
                    <!--                                      fill="white" />-->
                    <!--                                <path fill-rule="evenodd" clip-rule="evenodd"-->
                    <!--                                      d="M3.53846 4.82051C3.53846 4.11246 4.11246 3.53846 4.82051 3.53846L6.8718 3.53846C7.29663 3.53846 7.64103 3.19407 7.64103 2.76923C7.64103 2.3444 7.29663 2 6.8718 2L4.82051 2C3.26279 2 2 3.26279 2 4.82051L2 6.8718C2 7.29663 2.3444 7.64103 2.76923 7.64103C3.19407 7.64103 3.53846 7.29663 3.53846 6.8718L3.53846 4.82051Z"-->
                    <!--                                      fill="white" />-->
                    <!--                                <path fill-rule="evenodd" clip-rule="evenodd"-->
                    <!--                                      d="M3.53846 19.1795C3.53846 19.8875 4.11246 20.4615 4.82051 20.4615H6.8718C7.29663 20.4615 7.64103 20.8059 7.64103 21.2308C7.64103 21.6556 7.29663 22 6.8718 22H4.82051C3.26279 22 2 20.7372 2 19.1795L2 17.1282C2 16.7034 2.3444 16.359 2.76923 16.359C3.19407 16.359 3.53846 16.7034 3.53846 17.1282L3.53846 19.1795Z"-->
                    <!--                                      fill="white" />-->
                    <!--                                <path fill-rule="evenodd" clip-rule="evenodd"-->
                    <!--                                      d="M7.77996 5.36753C8.98255 5.23497 10.686 5.07692 12 5.07692C13.314 5.07692 15.0175 5.23497 16.22 5.36753C17.493 5.50784 18.4922 6.50702 18.6325 7.77996C18.765 8.98255 18.9231 10.686 18.9231 12C18.9231 13.314 18.765 15.0175 18.6325 16.22C18.4922 17.493 17.493 18.4922 16.22 18.6325C15.0175 18.765 13.314 18.9231 12 18.9231C10.686 18.9231 8.98255 18.765 7.77996 18.6325C6.50702 18.4922 5.50784 17.493 5.36753 16.22C5.23497 15.0175 5.07692 13.314 5.07692 12C5.07692 10.686 5.23497 8.98255 5.36752 7.77996C5.50784 6.50702 6.50702 5.50784 7.77996 5.36753ZM12 6.61539C10.7769 6.61539 9.14545 6.76479 7.94852 6.89673C7.3918 6.95809 6.95809 7.3918 6.89672 7.94852C6.76479 9.14545 6.61539 10.7769 6.61539 12C6.61539 13.2231 6.76479 14.8546 6.89672 16.0515C6.95809 16.6082 7.3918 17.0419 7.94852 17.1033C9.14545 17.2352 10.7769 17.3846 12 17.3846C13.2231 17.3846 14.8546 17.2352 16.0515 17.1033C16.6082 17.0419 17.0419 16.6082 17.1033 16.0515C17.2352 14.8546 17.3846 13.2231 17.3846 12C17.3846 10.7769 17.2352 9.14545 17.1033 7.94852C17.0419 7.3918 16.6082 6.95809 16.0515 6.89673C14.8546 6.76479 13.2231 6.61539 12 6.61539Z"-->
                    <!--                                      fill="white" />-->
                    <!--                            </svg>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
            </div>

            <!-- columns view -->
            <div v-if="terminal.items_view == 'columns'" class="grid grid-cols-5 gap-2">
                <div v-for="(group, index) in groups" :key="index">

                    <div
                        class="group border border-gray-100 h-13 cursor-pointer overflow-hidden rounded-sm flex items-center justify-center"
                        :style="{'background-color': group.color, 'border-color': group.color}">
                        <div class="truncate font-semibold">{{ group.name }}</div>
                    </div>

                    <item :initialitem="item" view="button" :initialquantity="quantity" @itemadded="handleItemAdded()"
                          @modalopened="modalopened" @modalclosed="modalclose"
                          v-for="(item, index_items) in pos_visible(group.items)" :key="index_items" />
                </div>
            </div>

            <div class="flex gap-2 pt-2 transaction-functionalities bottom pr-4" v-if="terminal.extra_basket_functionalities_position === 'bottom'">
                <div class="button button-blur button-wrapper">
                    <modal-account view="button" />
                </div>
                <div @click="$refs.booking.open()" class="button button-blur button-wrapper">
                    <div class="flex justify-center">
                        {{$t('translations.views.pos.pos-table.bookings')}}
                    </div>
                </div>
                <div @click="$refs.walletcheckcredit.open()" class="button button-blur button-wrapper">
                    <div class="flex justify-center">
                        {{$t('translations.views.pos.pos-table.card-balance')}}?
                    </div>
                </div>
                <div @click="$refs.walletaddcredit.open()" class="button button-blur button-wrapper">
                    <div class="flex justify-center">
                        {{$t('translations.views.pos.pos-table.top-up-card')}}
                    </div>
                </div>
                <div @click="$refs.preordercollect.open()" class="button button-blur button-wrapper">
                    <div class="flex justify-center">
                        {{$t('translations.views.pos.pos-table.pick-up-preorder')}}
                    </div>
                </div>
            </div>
        </div>


        <div class="transaction-area flex flex-col justify-between" :class="terminal.transaction_area_width">

            <div class="transaction-overview flex-grow overflow-y-hidden transaction-overview-table"

            >

                <transaction-table />

                <transaction layout="postable" />
            </div>

            <div class="transaction-buttons-area">
                <div class="flex gap-2 pt-2 transaction-functionalities">
                    <div class="flex justify-between space-x-2 flex-grow items-stretch"
                        :class="{'max-width-transaction-functionalities': terminal.extra_basket_functionalities_position === 'in-transaction-area'}"
                    >

                            <!-- clear transaction -->
                            <span class="inline-flex rounded-sm shadow-sm w-full" @click="transactionClear()">
                                <a class="button button-blur w-full square">
                                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd"
                                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                              clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </span>

                            <!-- line retour | admin only -->
                            <span v-if="terminal.admin_mode" class="inline-flex rounded-sm shadow-sm w-full">
                              <a @click="lineSelectedRetour()" class="button button-blur w-full square">
                                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                      <path fill-rule="evenodd"
                                            d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                            clip-rule="evenodd" />
                                  </svg>
                              </a>
                          </span>

                            <!--                             line discount | admin only-->
                            <!--                            <span v-if="terminal.admin_mode" class="inline-flex rounded-sm shadow-sm w-full">-->
                            <!--                              <a @click="lineSelectedDiscount(quantity)" class="button button-blur w-full square">-->
                            <!--                                  <svg class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
                            <!--                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"-->
                            <!--                                            d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />-->
                            <!--                                  </svg>-->
                            <!--                              </a>-->
                            <!--                          </span>-->

                            <!-- delete line -->
                            <span class="inline-flex rounded-sm w-full shadow-sm">
                                <a @click="lineSelectedDelete()" class="button button-blur square w-full">
                                    <svg class="h-5 w-full" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd"
                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                              clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </span>

                            <span class="inline-flex rounded-sm w-full shadow-sm">
                                <modal-discount />
                            </span>


                            <!-- open drawer -->
                            <span class="inline-flex rounded-sm w-full shadow-sm">
                              <a @click="drawerOpen()" class="button button-blur square w-full">
                                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                      <path
                                          d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z" />
                                      <path
                                          d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z" />
                                  </svg>
                              </a>
                          </span>
                        </div>
                    <div class="transaction-bar-extra-functionalities-buttons" v-if="terminal.extra_basket_functionalities_position === 'in-transaction-area'">
                        <div class="grid grid-cols-2 gap-2 text-xs">
                            <div class="button button-blur button-wrapper">
                                <modal-account view="button" />
                            </div>
                            <div class="button button-blur button-wrapper">
                                <modal-functions view="button" :table="table" />
                            </div>
                            <!-- <modal-booking view="button" /> -->
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-2 pt-2">
                    <!-- <div class="col-span-7">
                        <numpad ref="numpad" :times="true" @inputchanged="quantity = $event" />
                    </div> -->
                    <div class="col-span-1">
                        <div v-if="transaction.table_id" class="">
                            <div class="button button-ghost button-wrapper mb-2">
                                <modal-table-show />
                            </div>
                            <div class="button button-ghost button-wrapper">
                                <modal-table-history />
                            </div>
                        </div>

                        <div class="button button-ghost button-wrapper" v-else>
                            <modal-cashfunction />
                        </div>

                        <div class="flex mt-2" v-if="!transaction.table_id">
                            <div @click="gotoTransaction()" class="button button-filled flex-auto">
                                {{$t('translations.views.pos.pos-table.pay')}}
                            </div>
                        </div>
                    </div>

                    <div class="col-span-1">
                        <div class="">
                            <div class="button button-ghost button-wrapper" v-if="transaction.table_id">
                                <table-close />
                            </div>
                            <a v-else @click="tablesOpen()"
                               class="flex justify-center cursor-pointer button button-ghost">
                               {{$t('translations.views.pos.pos-table.open')}}
                            </a>
                        </div>

                        <div v-if="transaction.table_id" @click="printReceiptTable()"
                             class="col-span-1 button button-ghost mt-2">
                            <div class="flex justify-center">
                                {{$t('translations.views.pos.pos-table.bon-table')}}
                            </div>
                        </div>

                        <div v-else @click="printReceipt(terminal.last_transaction_id)" class="col-span-1 button button-ghost mt-2">
                            <div class="flex justify-center">
                                {{$t('translations.views.pos.pos-table.bon')}}
                            </div>
                        </div>
                        <!-- arie {{ terminal}} -->

                    </div>
                </div>
                <div class="flex mt-2" v-if="transaction.table_id">
                    <div @click="gotoTransaction()" class="button button-filled flex-auto">
                        {{$t('translations.views.pos.pos-table.pay')}}
                    </div>
                </div>
            </div>
        </div>

        <modal-reservations/>
        <!-- barcodescanner -->
        <barcode-scanner v-if="itemscanner" ref="barcodescanner" />

        <!-- modals -->
        <modal-subtotal ref="modalSubtotal" @selected="gotoTransaction()" />
        <modal-booking ref="booking" @opened="close()" />
        <modal-preorder-collect ref="preordercollect" :allow-scanner="true" />
        <modal-wallet-add-credit ref="walletaddcredit" :allow-scanner="true" />
        <modal-wallet-check-credit ref="walletcheckcredit" :allow-scanner="true" />
    </div>
</template>

<style scoped>

</style>

<script>

// mixins
import PosHelper from '../../mixins/pos.helper.js';

// components
import BarcodeScanner from '../../components/BarcodeScanner';
import TableClose from '../../components/TableClose';
import ModalAccount from '../../components/ModalAccount';
import ModalFunctions from '../../components/ModalFunctions';
import ModalCashfunction from '../../components/ModalCashfunction';
import ModalTableHistory from '../../components/ModalTableHistory';
import ModalTableShow from '../../components/ModalTableShow';
import TransactionTable from '../../components/TransactionTable';
import ModalDiscount from "../../components/ModalDiscount";
import ModalBooking from '../../components/ModalBooking';
import ModalPreorderCollect from '../../components/ModalPreorderCollect.vue';
import ModalWalletAddCredit from '../../components/ModalWalletAddCredit.vue';
import ModalWalletCheckCredit from '../../components/ModalWalletCheckCredit.vue';
import ModalReservations from '../../components/ModalReservations.vue';


export default {
    name: 'PosTable',
    mixins: [PosHelper],
    components: {
        BarcodeScanner,
        TableClose,
        ModalAccount,
        ModalFunctions,
        ModalCashfunction,
        ModalTableHistory,
        ModalTableShow,
        TransactionTable,
        ModalDiscount,
        ModalBooking,
        ModalPreorderCollect,
        ModalWalletCheckCredit,
        ModalWalletAddCredit,
        ModalReservations,
    },
    data () {
        return {
            quantity: null,
        };
    },

    mounted () {
        // check if there is a default table
        this.setDefaultTable();
    },

    methods: {
        transactionClear () {
            this.$store.dispatch('transaction/clear', this.quantity);

            // is table required => return to tables page
            if (this.terminal.startpage == 'tables') {
                this.$router.push({ name: 'pos.tables' });
            }

            this.setDefaultTable();
        },

        handleItemAdded () {
            this.clearInput();
        },

        clearInput () {
        },


    },
    computed: {
        table () {
            return this.$store.getters['transaction/table'];
        },
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
    },
};
</script>
