<template>
    <div v-if="view == 'button'" @click="open()" class="col-span-1 button-anchor">
        <div class="flex justify-between">
            {{$t('translations.components.modal-preorder-collect.get-pre-order')}}
        </div>
    </div>

    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal"
                 class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">

                    <modal-close @close="close()" />

                    <!-- select cashfunction -->
                    <div v-if="page == 'scanpreordercode'">
                        <div class="flex justify-between items-center mb-5">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                    <div>{{$t('translations.components.modal-preorder-collect.title')}}</div>
                                </h3>
                            </div>
                        </div>
                        <numpad @inputchanged="preordercode = $event" :allow-scanner="true"
                                @scanned="processBarcodeScan($event)" />
                    </div>
                    <!-- confirm cashfunction -->
                    <div v-else>
                        <div class="flex justify-between items-center">
                            <div class="mt-3 sm:mt-0 w-full">
                                <div class="flex justify-between w-full flex-wrap">
                                    <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                        {{$t('translations.components.modal-preorder-collect.get-pre-order')}}
                                    </h3>
                                </div>

                                <div class="mb-5" v-if="preorder.relationships.lines.length == 0">
                                    {{$t('translations.components.modal-preorder-collect.no-pre-order')}}
                                </div>

                                <div v-else>
                                    <div class="overflow-y-auto scrollbar-hide" style="height: 370px">
                                        <ul class="list-tertiary">
                                            <li v-for="(line, index_line) in preorder.relationships.lines"
                                                :key="index_line" class="list-item grid grid-cols-12">
                                                <div class="col-span-2">{{ parseFloat(line.attributes.quantity) }}</div>
                                                <div class="col-span-8">{{ line.attributes.description }}</div>
                                                <div class="col-span-2 text-right">
                                                    {{ vueNumberFormat(line.attributes.total) }}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer p-4 sm:px-6 sm:flex sm:flex-row-reverse gap-5">
                    <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto">
                        <a v-if="page == 'scanpreordercode'" @click="collectPreorder()"
                           class="button button-filled button-large">
                            {{$t('translations.components.modal-preorder-collect.confirm')}}
                        </a>

                        <a v-if="page == 'result'" @click="submitCollect()" class="button button-filled button-large">
                            {{$t('translations.components.modal-preorder-collect.process')}}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import md5 from 'js-md5';

// components
import Numpad from './Numpad';
import ModalClose from "./ModalClose";

export default {
    name: 'ModalPreorderCollect',
    emits: ['opened'],
    components: {
        ModalClose,
        Numpad,
    },
    data () {
        return {
            active: false,
            page: 'scanpreordercode',
            preordercode: '',
            preorder: null,
        };
    },

    methods: {
        open () {
            // disable item barcodescanner
            this.$store.dispatch('settings/itemscanner', false);
            // this.setCashfunctions();
            this.page = 'scanpreordercode';
            this.active = true;
            this.$emit('opened');

            this.preorder = null;
            // this.preordercode = '51AB71E1';

            // todo: remove for production
            // this.barcodeScanned(process.env.VUE_APP_WALLETTOKEN)
            this.$store.dispatch('terminal/setModalActive', true);
        },

        close () {
            // activate item barcodescanner
            this.$store.dispatch('settings/itemscanner', false);
            this.$store.dispatch('terminal/setModalActive', false);

            this.active = false;
        },

        // search item on barcode scanner
        processBarcodeScan (barcode) {

            if (barcode.length > 0) {
                this.preordercode = barcode;

                this.collectPreorder();
            }
        },

        async collectPreorder () {
            if (this.preordercode.length > 0) {

                // todo loading spinner
                await axios.post('/preorders/collect', {
                    preordercode: this.preordercode,
                }).then(response => {
                    if (response.data.result == 'success') {
                        this.preorder = response.data.data;
                        this.page = 'result';
                    } else {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Ongeldig",
                            text: response.data.message,
                        }, 2000);
                    }
                }).catch(error => {
                    if (error.response.status == 404) {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Niet gevonden",
                            text: "Gescande artikel is niet gevonden",
                        }, 2000);
                    } else {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Fout opgetreden",
                            text: "Oeps er ging iets fout..",
                        }, 2000);
                        console.error(error);
                    }
                });
            }
        },

        async submitCollect () {
            if (!this.preorder) {
                return;
            }

            const response = await axios.post('/preorders/process', {
                preorder_id: this.preorder.id,
            });

            if (response && response.data.result === 'failed') {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Ongeldig",
                    text: response.data.message,
                }, 2000);

                return;
            }

            for (let line of this.preorder.relationships.lines) {

                let data = {
                    parent_id: null,
                    item_id: line.attributes.item_id,
                    item_parent_id: null,
                    addon: false,
                    composed_child: false,
                    barcode: line.attributes.barcode,
                    description: line.attributes.description,
                    taxrate: line.attributes.taxrate,
                    rate: line.attributes.rate,

                    kitchen_groceries: [],
                    variant_id: null,
                    variant_label: null,

                    // addons?!
                    addons: [],
                    card: null,
                    cardnumber: null,
                    notes: null,
                };

                data.id = md5(JSON.stringify(data));
                data.quantity = parseFloat(line.attributes.quantity);

                this.$store.dispatch('transaction/addItem', data);
            }

            this.$notify({
                group: "notifications",
                type: 'success',
                title: "Verwerkt",
                text: 'De preorder is succesvol verwerkt',
            }, 2000);
            this.close();
        },
    },
};
</script>
