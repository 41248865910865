<template>
    <modal-manager-login ref="managerlogin" @loggedin="showModal()" />

    <a class="cursor-pointer text-white" @click="open()">
        <svg class="h-6 h-6" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clip-rule="evenodd" />
        </svg>
    </a>
    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal"
                 class="inline-block modal-extra-large align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }"><!--setFieldValue setValues -->
                    <div class="modal-content flex flex-col justify-between">

                        <modal-close @close="close()" />

                        <div class="sm:flex sm:items-start mb-5">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                    {{$t('translations.components.modal-settings.title')}}
                                </h3>
                            </div>
                        </div>

                        <div class="flex">
                            <div class="flex-grow mr-10">
                                <div class="mb-5 default-input">
                                    <label for="printer_ipaddress" class="block"
                                           :class="{'text-red-500': errors.printer_ipaddress}">{{$t('translations.components.modal-settings.ip-address')}} *</label>
                                    <div class="">
                                        <Field type="text" readonly name="printer_ipaddress" v-model="form.printer_ipaddress"
                                               :class="{'error': errors.printer_ipaddress}" />
                                    </div>
                                </div>

                                <div class="mb-5 default-input">
                                    <label for="printer_protocol" class="block"
                                           :class="{'text-red-500': errors.printer_protocol}">{{$t('translations.components.modal-settings.protocol')}} *</label>
                                    <Field name="printer_protocol" as="select" v-model="form.printer_protocol"
                                           :class="{'error': errors.printer_protocol}">
                                        <option></option>
                                        <option value="http">http</option>
                                        <option value="https">https</option>
                                    </Field>
                                </div>

                                <div class="mb-5 default-input">
                                    <label for="printer_paperwidth" class="block"
                                           :class="{'text-red-500': errors.printer_paperwidth}">{{$t('translations.components.modal-settings.paper')}} *</label>
                                    <Field name="printer_paperwidth" as="select" v-model="form.printer_paperwidth"
                                           :class="{'error': errors.printer_paperwidth}">
                                        <option></option>
                                        <option value="58mm">58mm</option>
                                        <option value="72mm">72mm</option>
                                        <option value="82mm">82mm</option>
                                        <option value="112mm">112mm</option>
                                    </Field>
                                </div>

                                <div class="mb-5 default-input">
                                    <label for="printer_autocutter" class="block"
                                           :class="{'text-red-500': errors.printer_autocutter}">{{$t('translations.components.modal-settings.auto-cut')}}
                                        *</label>
                                    <Field name="printer_autocutter" as="select" v-model="form.printer_autocutter"
                                           :class="{'error': errors.printer_autocutter}">
                                        <option></option>
                                        <option value="full">full</option>
                                        <option value="partial">partial</option>
                                    </Field>
                                </div>

                            </div>

                            <div class="mt-6">
                                <div class="grid grid-cols-3 mt-2 gap-2">
                                <span class="key inline-flex rounded-sm  shadow-sm">
                                    <a @click="setInput('1')" class="button button-blur button-wide">
                                        1
                                    </a>
                                </span>
                                    <span class="key inline-flex rounded-sm  shadow-sm">
                                    <a @click="setInput('2')" class="button button-blur button-wide">
                                        2
                                    </a>
                                </span>
                                    <span class="key inline-flex rounded-sm  shadow-sm">
                                    <a @click="setInput('3')" class="button button-blur button-wide">
                                        3
                                    </a>
                                </span>
                                </div>

                                <div class="grid grid-cols-3 mt-2 gap-2">
                                <span class="key inline-flex rounded-sm  shadow-sm">
                                    <a @click="setInput('4')" class="button button-blur button-wide">
                                        4
                                    </a>
                                </span>
                                    <span class="key inline-flex rounded-sm  shadow-sm">
                                    <a @click="setInput('5')" class="button button-blur button-wide">
                                        5
                                    </a>
                                </span>
                                    <span class="key inline-flex rounded-sm  shadow-sm">
                                    <a @click="setInput('6')" class="button button-blur button-wide">
                                        6
                                    </a>
                                </span>
                                </div>

                                <div class="grid grid-cols-3 mt-2 gap-2">
                                <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                                    <a @click="setInput('7')" class="button button-blur button-wide">
                                        7
                                    </a>
                                </span>
                                    <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                                    <a @click="setInput('8')" class="button button-blur button-wide">
                                        8
                                    </a>
                                </span>
                                    <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                                    <a @click="setInput('9')" class="button button-blur button-wide">
                                        9
                                    </a>
                                </span>
                                </div>

                                <div class="grid grid-cols-3 mt-2 gap-2">
                                <span class="key inline-flex rounded-sm  shadow-sm">
                                    <a @click="setInput('.')" class="button button-blur button-wide">
                                        .
                                    </a>
                                </span>
                                    <span class="key inline-flex rounded-sm  shadow-sm">
                                    <a @click="setInput('0')" class="button button-blur button-wide">
                                        0
                                    </a>
                                </span>
                                    <span class="key inline-flex rounded-sm  shadow-sm">
                                    <a @click="clear()" class="button button-blur button-wide">
                                        {{$t('translations.components.modal-settings.clear')}}
                                    </a>
                                </span>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer pt-4 sm:px-6 sm:flex sm:flex-row-reverse">
                            <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto">
                                <button type="submit" class="button button-filled button-large">
                                    {{$t('translations.components.modal-settings.save')}}
                                </button>
                            </span>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

//mixins
import KeyboardHelper from '../mixins/keyboard.helper.js';

import {
    Field,
    Form,
} from 'vee-validate';
import * as yup from "yup";

import ModalManagerLogin from "./ModalManagerLogin";
import ModalClose from "./ModalClose";

export default {
    mixins: [KeyboardHelper],
    components: {
        ModalManagerLogin,
        ModalClose,
        Field,
        Form,
    },
    data () {
        const schema = yup.object().shape({
            printer_ipaddress: yup.string().required(),
            printer_protocol: yup.string().required().max(255),
            printer_paperwidth: yup.string().required().max(255),
            printer_autocutter: yup.string().required().max(255),
        });

        return {
            active: false,
            schema,
            form: {
                printer_ipaddress: '',
                printer_protocol: '',
                printer_paperwidth: '',
                printer_autocutter: '',
            },
        };
    },
    methods: {
        open () {
            this.$refs.managerlogin.open();
        },
        showModal () {
            this.form.printer_ipaddress = this.$store.getters['printer/ipaddress'],
            this.form.printer_protocol = this.$store.getters['printer/protocol'],
            this.form.printer_paperwidth = this.$store.getters['printer/paperwidth'],
            this.form.printer_autocutter = this.$store.getters['printer/autocutter'],

            this.active = true;

            if (this.form.printer_ipaddress === null) {
                this.form.printer_ipaddress = '';
            }
        },

        close () {
            this.active = false;
        },

        setInput (value) {
            this.form['printer_ipaddress'] += value;
        },

        clear () {
            this.form['printer_ipaddress'] = '';
        },

        onSubmit (data) {
            axios.post('/terminal/settings', data).then(() => {
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Succesvol",
                    text: "Instellingen opgeslagen",
                }, 2000);
                location.reload();
            }).catch(error => {
                if(error.response.status === 401){
                        return;
                }else{
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Fout opgetreden",
                        text: "Oeps er ging iets fout..",
                    }, 2000);
                    console.error(error);
                }
            });
        },
    },
    computed: {
        printer_ipaddress () {
            return this.$store.getters['printer/ipaddress'];
        },
        printer_protocol () {
            return this.$store.getters['printer/protocol'];
        },
        printer_paperwidth () {
            return this.$store.getters['printer/paperwidth'];
        },
        printer_autocutter () {
            return this.$store.getters['printer/autocutter'];
        },
    },
};
</script>
