<template>
    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal"
                 class="reservation-create-modal modal-extra-large inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content create-reservation">
                    <modal-close @close="close()" />
                    <div class="">

                        <div class="flex items-center justify-between mb-4 w-full pr-12">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl mb-0" id="modal-headline">
                                Create Reservation
                            </h3>
                        </div>

                        <Form @submit="onSubmit" :validation-schema="schema"
                            v-slot="{ errors }"
                            class="reservation-form"
                        ><!--setFieldValue setValues -->
                        
                            <modal-close @close="close()" />

                            <div class="date-picker-create-wrapper pick-date">
                                <date-picker class="date-picker"
                                    @dayclick="setTimeslots()"
                                    v-model="form.date" 
                                    color="gray" 
                                    :min-date="minimum_date"
                                    :max-date="timeslot.attributes?.maximum_date"
                                    is-dark
                                    format="yyyy-MM-dd"
                                />
                            </div>

                            <div class="fields-wrapper">
                                <div class="flex-grow ">
                                    <div class="default-input">
                                        <label for="area_id" class="block mb-2">Area</label>
                                        <div class="area-items">
                                            <div class="area-item" v-for="(area, index) in areas.data" :key="index"
                                                @click="form.area_id = area.id, selectArea(area);"
                                                :class="{'active': form.area_id == area.id}"
                                            >
                                                {{ area.attributes.name }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="slot-table-wrapper">
                                    <!-- <div class="slot-table-wrapper" v-if="form.area_id"> -->
                                        <div class="has-ending-time" v-if="timeslot.attributes && timeslot.attributes.has_ending_time == true">
                                            <label class="mb-1 block text-base font-bold"> Kies aankomst- en vertrektijd </label>
                                            <div
                                                v-if="fullDayAvailable"
                                                class="relative flex items-center h-5 mt-4 mb-2 inline-block cursor-pointer"
                                                @click="toggleSelectFullDay()"
                                            >
                                                <input type="checkbox" class="mr-2 cursor-pointer" :checked="full_day_checked" />
                                                <label class="cursor-pointer block text-base font-bold"> Selecteer de hele dag </label>
                                            </div>
                                            <div class="flex gap-4 w-full default-input" v-if="full_day_checked === false">
                                                <select
                                                    v-if="timeslot.attributes?.timeslots.length > 0"
                                                    @change="processTimeslotsAvailableAfterStart()"
                                                    v-model="reservation_start_time"
                                                    class="mt-1 flex-grow radius-anchor select-start"
                                                >
                                                    <option value=""></option>
                                                    <template v-for="(timeslot, index) in timeslot.attributes.timeslots" :key="index">
                                                        <option v-if="timeslot.available">
                                                            {{ timeslot.from }}
                                                        </option>
                                                    </template>
                                                </select>

                                                <select
                                                    v-if="timeslot.attributes?.timeslots.length > 0"
                                                    v-model="reservation_end_time"
                                                    @change="processTimeslotsAvailableAfterStart()"
                                                    class="mt-1 flex-grow radius-anchor select-end"
                                                >
                                                    <option value=""></option>
                                                    <template v-for="(timeslot, index) in timeslotsAvailableAfterStart" :key="index">
                                                        <option v-if="timeslot.available">
                                                            {{ timeslot.to }}
                                                        </option>
                                                    </template>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="default-input" v-else-if="form.area_id">
                                            <label for="timeslot" class="block mb-2">
                                                Tijdslot
                                            </label>
                                            <select name="timeslot"
                                                v-model="form.timeslot"
                                            >
                                                <option></option>
                                                <option v-for="(timeslot, index) in timeslot.attributes?.timeslots" :key="index" 
                                                    :value="timeslot.label"
                                                    >
                                                        {{ timeslot.label }}
                                                </option>
                                            </select>
                                        </div>

                                        <div
                                            class="default-input table"
                                            v-if="form.timeslot?.length && tables && tables.data?.length > 0 && timeslot.attributes.reserve_table == true"
                                        >
                                            <label for="table" class="block text-base mb-2 font-bold"
                                                :class="{'text-red-500': errors.table}"
                                                >Tafel *
                                                <div v-if="!tablesAvailable(tables.data)" class="text-red-500">
                                                    {{ $t('reservation.no_tables_available') }}
                                                </div>
                                            </label>
                                            <div class="mt-1">
                                                <Field
                                                    as="select"
                                                    @change="setTable($event)"
                                                    name="table"
                                                    :class="{'text-red-500': errors.table}"
                                                    class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent select-table"
                                                >
                                                    <option v-for="(table, index) in tables.data" :value="table.id" :key="index" :disabled="table.available == false">
                                                        {{ table.name }} <span v-if="!table.available"> --- Niet beschikbaar</span>
                                                    </option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <template v-if="form.timeslot"> -->
                                        <div class="mb-4 default-input">
                                            <label for="name"
                                            :class="{'text-red-500': errors.name}"
                                             class="block mb-2"
                                                >Name *</label>
                                            <div class="">
                                                <Field type="text" 
                                                @click="setActiveInput"
                                                :class="{'error': errors.name}"
                                                readonly name="name" v-model="form.name" />
                                            </div>
                                        </div>

                                        <div class="flex gap-4">
                                            <div class="mb-4 default-input flex-grow">
                                                <label for="email" class="block mb-2"
                                                    :class="{'text-red-500': errors.email}"
                                                    >Email *</label>
                                                <div class="">
                                                    <Field type="text" 
                                                    @click="setActiveInput"
                                                    :class="{'error': errors.email}"
                                                    readonly name="email" v-model="form.email" />
                                                </div>
                                            </div>

                                            <div class="mb-4 default-input flex-grow">
                                                <label for="phone_number" class="block mb-2"
                                                    :class="{'text-red-500': errors.phone_number}"
                                                    >Phone number *</label>
                                                <div class="">
                                                    <Field type="text" 
                                                    @click="setActiveInput"
                                                    :class="{'error': errors.phone_number}"
                                                    readonly name="phone_number" v-model="form.phone_number"/>
                                                </div>
                                            </div>
                                        </div>
                                </div>

                                <div class="keyboard-wrapper"
                                     :class="{'hidden': !keyboardActive}"
                                >
                                    <keyboard
                                            @input="changed"
                                            :layouts="[

                                        '1234567890{backspace:backspace}|qwertyuiop|asdfghjkl|{shift:goto:1}zxcvbnm_,.-|{space:space}',
                                        '!@#$%^&*(){backspace:backspace}|QWERTYUIOP|ASDFGHJKL|{shift:goto:0}ZXCVBNM:;|{space:space}'
                                    ]"
                                            :maxlength="48"
                                    >
                                        
                                    </keyboard>

                                    <div class="close-keyboard" @click="keyboardActive = false">
                                        <svg class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="#303039" viewBox="0 0 24 24" stroke="#303039">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                </div>

                                <div class="reservation-button-flexer flex-wrap">
                                    <button 
                                        class="button button-large button-danger flex-grow" @click="close()">
                                        Annuleren
                                    </button>
                                    <button type="submit" class="button button-filled button-large"
                                        :class="{'disabled': !validTimeslot}"
                                    >
                                        Create

                                        <svg v-if="saving" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                    stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import keyboard from 'vue-keyboard';

import ModalClose from "./ModalClose";
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';

import {
    Field,
    Form,
} from 'vee-validate';
import * as yup from "yup";

export default {
    name: 'ModalReservationCreate',
    emits: ['created', 'closed'],
    props: ['sentDate'],
    components: {
        DatePicker,
        Form,
        Field,
        keyboard,
        ModalClose,
    },
    data () {
        const schema = yup.object().shape({
            name: yup.string().required(),
            email: yup.string().required().max(255),
            phone_number: yup.string().required().max(255),
        });

        return {
            schema,
            active: false,
            initialquantity: 1,
            areas: [],
            tables: [],
            activeInput: null,
            keyboardActive: false,
            timeslotsAvailableAfterStart: null,
            full_day_checked: false,
            reservation_items: [],
            timeslots: [],
            tableStillAvailable: true,
            confirmed: false,
            timeslot: '',
            reservation_start_time: null,
            reservation_end_time: null,
            minimum_date: moment().format('YYYY-MM-DD'),
            form: {
                name: '',
                date: moment().format('YYYY-MM-DD'),
                email: '',
                phone_number: '',
                timeslot_id: null,
                area_id: null,
                table_id: null,
                quantity: 1,
            },
        };
    },

    mounted() {
        this.setAreas();
    }, 

    watch: {
        reservation_start_time: function () {
            if (this.reservation_start_time && this.reservation_end_time && this.reservation_start_time !== this.reservation_end_time) {
                this.combineTimeslotsAndReservationItems();
            } else {
                this.form.timeslot = null;
                this.tables = null;
            }
        },

        reservation_end_time: function () {
            if (this.reservation_end_time && this.reservation_start_time && this.reservation_start_time !== this.reservation_end_time) {
                this.combineTimeslotsAndReservationItems();
            } else {
                this.form.timeslot = null;
                this.tables = null;
            }
        },

        timeslot: function () {
            this.timeslotsAvailableAfterStart = this.timeslot.attributes?.timeslots;
        },
    },
  
    methods: {
        open () {
            this.active = true;

            // this.processedSentDate = moment(this.sentDate).format('YYYY-MM-DD');

            this.resetReservationData();
        },

        close () {
            this.$emit('closed');
            this.active = false;
            this.saving = false;
        },

        changed(value) {
            if (value.length > 0)
                this.form[this.activeInput] += value
            else {
                this.form[this.activeInput] = this.form[this.activeInput].slice(0, -1)
            }
        },

        resetReservationData() {
            this.confirmed = false;

            let i;

            let startSelect = document.querySelector('.select-start');
            let endSelect = document.querySelector('.select-end');
            let tableSelect = document.querySelector('.select-table');

            if (startSelect?.length) {
                for(i = 0; i < startSelect.length; i++) {
                    startSelect[i].selectedIndex = 0;
                }  
            }

            if (endSelect?.length) {
                for(i = 0; i < endSelect.length; i++) {
                    endSelect[i].selectedIndex = 0;
                } 
            }

            if (tableSelect?.length) {
                for(i = 0; i < tableSelect.length; i++) {
                    tableSelect[i].selectedIndex = 0;
                } 
            }

            this.form.name = '';
            this.form.date = moment().format('YYYY-MM-DD');
            this.form.email = '';
            this.form.phone_number = '';
            this.form.timeslot_id = null;
            this.form.area_id = null;
            this.form.table_id = null;
            this.form.quantity = 1;
        },

        async setAreas() {
            await axios.get('reservations/areas?locationId=' + this.location.id).then(response => {
                this.areas = response.data;
            });
        },

        setActiveInput(evt) {
            this.keyboardActive = true;
            this.activeInput = evt.target.name;
        },

        async selectArea(area) {
            console.log(this.form);
            let oldDate = JSON.parse(JSON.stringify(this.form.date));
            this.reservation_start_time = '';
            this.reservation_end_time = '';
            this.form.area_id = area.id;

            await this.setTimeslots();

            let newDate = this.form.date;

            // selected ndate not available in newly selected timeslot
           if (oldDate !== newDate) {
                this.$notify({ group: "notifications", type: 'error', title: "Area niet beschikbaar op geselecteerde datum" }, 2000);
                this.form.date = moment().format('YYYY-MM-DD')
           }
        },

        tablesAvailable(tables) {
            return tables.filter((table) => table.available == true).length;
        },

        combineTimeslotsAndReservationItems(timeslot = false) {
            this.reservation_items = [];
            let timeslots = [];
            let itemToPush = '';

            if (this.timeslot.attributes.has_ending_time == true) {
                let starting_slot = this.timeslot.attributes.timeslots.filter((timeslot) => timeslot.from == this.reservation_start_time);
                let ending_slot = this.timeslot.attributes.timeslots.filter((timeslot) => timeslot.to == this.reservation_end_time);

                let startIndex = this.timeslot.attributes.timeslots.indexOf(starting_slot[0]);
                let endIndex = this.timeslot.attributes.timeslots.indexOf(ending_slot[0]);

                //get all selected timeslots
                for (let i = startIndex; i <= endIndex; i++) {
                    timeslots.push(this.timeslot.attributes.timeslots[i].slot);
                }

                // check for products when multipe timeslots options are used
                let fitting_multiple_timeslot_options = [];
                let multiple_timeslot_options = this.timeslot.attributes.reservation_items_multiple_timeslots;
                if (multiple_timeslot_options) {
                    fitting_multiple_timeslot_options = multiple_timeslot_options.filter((option) => option.quantity <= timeslots.length);
                }

                if (this.timeslot.attributes.fixed_fee == true) {
                    this.reservation_items.push(this.timeslot.attributes.reservation_item);
                } else if (fitting_multiple_timeslot_options.length) {
                    this.reservation_items.push(fitting_multiple_timeslot_options[0].item);
                } else {
                    for (let i = startIndex; i <= endIndex; i++) {
                        itemToPush = null;
                        // add item for individual timeslot

                        if (this.timeslot.attributes.timeslots[i].reservation_item) {
                            itemToPush = this.timeslot.attributes.timeslots[i].reservation_item;
                        } else if (this.form.area_id) {
                            //add item for area
                            let active_area = this.areas.data.filter((area) => area.id == this.form.area_id);

                            if (active_area.length && active_area[0].attributes.area_item) {
                                itemToPush = active_area[0].attributes.area_item;
                            } else if (this.timeslot.attributes.reservation_item) {
                                //global timeslot item
                                itemToPush = this.timeslot.attributes.reservation_item;
                            }
                        } else if (this.timeslot.attributes.reservation_item) {
                            //global timeslot item (duplicate code to make more readable);
                            itemToPush = this.timeslot.attributes.reservation_item;
                        }

                        if (itemToPush) {
                            this.reservation_items.push(itemToPush);
                        }
                    }
                }

                this.form.timeslot = timeslots;
            } else {
                this.form.timeslot = [timeslot.slot];
                timeslots.push(timeslot.slot);

                // duplicate code fragment
                if (this.form.area_id) {
                    //add item for area
                    let active_area = this.areas.filter((area) => area.id == this.form.area_id);

                    if (active_area.length && active_area[0].attributes.area_item) {
                        this.reservation_items.push(active_area[0].attributes.area_item);
                    } else if (this.timeslot.attributes.reservation_item) {
                        //global timeslot item
                        this.reservation_items.push(this.timeslot.attributes.reservation_item);
                    }
                }
            }

            this.setTables();
        },

        toggleSelectFullDay() {
            this.full_day_checked = !this.full_day_checked;

            if (this.full_day_checked === true) {
                this.reservation_start_time = document.querySelector('.select-start')[1].value;
                this.reservation_end_time = document.querySelector('.select-end')[document.querySelector('.select-end').length - 1].value;

                this.combineTimeslotsAndReservationItems();
            } else {
                this.reservation_start_time = null;
                this.reservation_end_time = null;
                this.form.timeslot = null;
            }
        },

        processTimeslotsAvailableAfterStart() {
            if (this.timeslot) {
                if (this.reservation_start_time) {
                    let starting_slot = this.timeslot.attributes.timeslots.filter((timeslot) => timeslot.from == this.reservation_start_time);

                    let startIndex = this.timeslot.attributes.timeslots.indexOf(starting_slot[0]);

                    let slotsAfterStart = this.timeslot.attributes.timeslots.slice(startIndex);

                    let slotsWhileAvailable = [];
                    let length = slotsAfterStart.length;
                    for (let i = 0; i < length; i++) {
                        if (slotsAfterStart[i].available == true) {
                            slotsWhileAvailable.push(slotsAfterStart[i]);
                        } else {
                            break;
                        }
                    }

                    this.timeslotsAvailableAfterStart = slotsWhileAvailable;
                } else {
                    if (this.timeslot?.data?.attributes.timeslots) {
                        this.timeslotsAvailableAfterStart = this.timeslot.data.attributes.timeslots;
                    }
                }
            }

            // if (this.timeslot && this.timeslot.attributes && this.timeslot.attributes.timeslots) {

            //     if (this.timeslot.attributes?.timeslots.length == this.timeslotsAvailableAfterStart.length) {
            //         this.fullDayAvailable = true;
            //     } else {
            //         this.fullDayAvailable = false;
            //     }
            // }
        },

        async setTables() {
            await axios.post('reservations/areas/tables', {
                area_id: this.form.area_id,
                timeslots: this.form.timeslot,
                date: this.form.date,
            }).then(response => {
                this.tables = response.data;
            });
        },

        setTable(evt) {
            this.form.table_id = evt.target.value;
        },

        async setTimeslots() {
            this.form.date = moment(this.form.date).format('YYYY-MM-DD');
            if (this.form.date && this.form.area_id) {
                await axios.post('/timeslots', {
                    date: this.form.date,
                    location_id: this.location.id,
                    function: 'reservation',
                    area_id: this.form.area_id,
                    quantity: this.form.quantity,
                }).then(response => {
                    this.timeslot = response.data;
                });
            } else {
                this.timeslot = false;
                return;
            }

            this.reservation_start_time = null;
            this.reservation_end_time = null;

            if (!this.timeslot) {
                return;
            }

            if (moment(this.form.date).format('YYYY-MM-DD') > moment(this.timeslot.attributes?.maximum_date).format('YYYY-MM-DD')) {
                this.form.date = '';
            }

            // reset timeslot
            this.form.timeslot = '';
            this.form.timeslot_id = null;

            // set default quantity
            if (!this.form.quantity && this.timeslot.attributes.resources_defaultvalue) {
                this.form.quantity = parseInt(this.timeslot.attributes.resources_defaultvalue);
            }
        },

        async onSubmit() {

            this.saving = true;
            let reservation_type = this.timeslot.attributes.reservation_fee_type;
            let reservationFeeOnTableTab = null;
            let reservationFeeDepositLine = null;
            let payment = false;

            if (['product', 'deposit'].includes(reservation_type) == true && this.timeslot.attributes.reservation_fee_on_table_tab == false) {
                payment = true;
            }

            // Logic for adding products or deposit to table;
            reservationFeeOnTableTab = reservation_type;

            if (
                reservationFeeOnTableTab === 'deposit' &&
                this.timeslot.attributes.cashfunction_id &&
                this.timeslot.attributes.cashfunction_price
            ) {
                let quantityToAdd = 1;

                if (this.timeslot.attributes?.fee_per_person == true) {
                    quantityToAdd = this.form.quantity;
                }

                reservationFeeDepositLine = {
                    id: 'reservation',
                    fixed_item: true,
                    line_is_fee: false,
                    type: 'cashinout',
                    cashfunction_id: this.timeslot.attributes.cashfunction_id,
                    description: 'Reservation',
                    quantity: quantityToAdd,
                    kitchen_groceries: [],
                    rate: this.timeslot.attributes.cashfunction_price,
                    rate_without_discount: this.timeslot.attributes.cashfunction_price,
                    total: this.timeslot.attributes.cashfunction_price,
                };
            }

            if (typeof this.form.timeslot == 'string') {
                this.form.timeslot = [this.form.timeslot];
            }

            // store reservation
            await axios.post('reservations', {
                type: 'pos',
                timeslot_id: this.timeslot.id,
                area_id: this.form.area_id,
                location_id: this.location.id,
                table_id: this.form.table_id,
                date: this.form.date,
                quantity: this.form.quantity,
                timeslot: this.form.timeslot,
                email: this.form.email,
                name: this.form.name,
                phone_number: this.form.phone_number,
                notes: this.form.notes,
                payment: payment,
                has_ending_time: this.timeslot.attributes.has_ending_time,
                fixed_reservation_fee: this.timeslot.attributes.fixed_fee,
                fee_per_person: this.timeslot.attributes.fee_per_person,
                reservation_fee_on_table_tab: reservationFeeOnTableTab,
                reservation_fee_products: this.reservation_items,
                reservation_fee_deposit_line: reservationFeeDepositLine,
            }).then(response => {
                console.log(response.data);
                
                this.resetReservationData();
                this.close();

                this.$notify({ group: "notifications", type: 'success', title: "Reservering aangemaakt" }, 2000);
            });
        },

        // Show starting time of first timeslotbooking and ending time of last timeslot booking
        processedTimeslot(reservation) {
            let last_slot = reservation[reservation.length - 1].timeslot.split(' ');

            return reservation[0].timeslot.split(' ')[0] + ' - ' + last_slot[last_slot.length - 1];
        },

        // todo. assign reservation to table. On other button
    },

    computed: {
        location() {
			return this.$store.getters['location/location'];
        },

        transaction() {
			return this.$store.getters['transaction/transaction'];
        },

        fullDayAvailable() {
            //Only allow selectFullDay when not today
            return moment(this.form.date).format('YYYY-MM-DD') != moment().format('YYYY-MM-DD');
        },

        validTimeslot() {
            if (this.timeslot?.attributes?.reserve_table == true && !this.form.table_id) {
                return false;
            } 

            // add specify quantity (groupsize) to pos reservation
            // when done, these are the checks for valid form.quantity

            // let quantityValid = false;
            // if (this.timeslot?.attributes?.reserve_table == false) {
            //     quantityValid = true;
            // }

            // if (this.timeslot?.attributes?.group_size_mode == 'no_group_size') {
            //     quantityValid = true;
            // } else if (this.timeslot?.attributes?.group_size_mode == 'group_size') {
            //     quantityValid = true;
            // }


            if (this.reservation_start_time && this.reservation_end_time && this.form.area_id) {
                return true;
            } 

            // return quantityValid && this.form.timeslot && this.form.date; (when quanitty added);

            return this.form.timeslot && this.form.date && this.form.area_id;
        },
    },
};
</script>
