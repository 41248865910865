<template>

    <div v-if="clerk.id" @click="closeClerk()" class="close-clerk">

        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 50 50" x="0px" y="0px" fill="currentColor" stroke="currentColor" background="currentColor">
            <path fill="currentColor" stroke="currentColor" d="M31,26a9,9,0,1,0-12,0,12.86,12.86,0,0,0-8.61,12.12,1.54,1.54,0,0,0,3.08,0,9.79,9.79,0,0,1,9.78-9.78h3.54a9.79,9.79,0,0,1,9.78,9.78,1.54,1.54,0,1,0,3.08,0A12.86,12.86,0,0,0,31,26ZM25,13.45a5.89,5.89,0,1,1-5.89,5.89A5.89,5.89,0,0,1,25,13.45Z">

            </path>
        </svg>
    </div>

    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">

        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="this.closeModal()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal" class="modal-centering inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">
                    <div class="flex justify-between items-center mb-5">
                        <div class="sm:mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                {{$t('translations.components.modal-clerk-close.auto-logout')}} {{countDown}}
                            </h3>

                            <p v-if="line" class="leading-6 text-base mt-2 font-bold" id="modal-headline">
                                {{ line.description }}
                            </p>
                        </div>
                        <modal-close @close="close()" />
                    </div>
                    <div>
                        <div class="mb-5">{{$t('translations.components.modal-clerk-close.auto-close')}}
                        </div>
                    </div>
                </div>
                <div class="modal-footer p-4 sm:px-6 sm:flex sm:flex-row-reverse">
                    <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto">
                        <span @click="this.closeClerk()"
                           class="button button-filled button-large button-danger">
                           {{$t('translations.components.modal-clerk-close.logout')}}
                        </span>
                    </span>
                    <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto mr-4">
                        <span @click="this.closeModal()"
                           class="button button-filled button-large">
                           {{$t('translations.components.modal-clerk-close.next')}}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModalClose from "./ModalClose";

export default {
    components: {
      ModalClose,
    },
    data: function () {
        return {
            active: false,
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
            logoutTimer: null,
            countDown: false,
            duration:  25 * 1000,
        }
    },

    mounted () {
        if (this.clerk.id && this.terminal.clerk_logout == 'auto') {
            this.events.forEach(function (event) {
                window.addEventListener(event, this.resetTimer);
            }, this);

            this.setTimers();
        }
    },

    unmounted () {
        this.clearTimeouts();
    },

    methods: {

        // timeout functions
        setTimers: function () {
            this.logoutTimer = setTimeout(this.openModal, this.duration);
        },

        resetTimer: function () {
            clearTimeout(this.logoutTimer)
            this.setTimers();
        },

        clearTimeouts() {
            clearTimeout(this.logoutTimer);
        },


        openModal: function () {
            if (this.$route.name == 'transaction' || this.$route.name == 'clerk.login') {
                this.resetTimer();
                return;
            }

            if (this.clerk.id) {
                this.active = true;
                this.countDown = 5;
            }
        },

        closeModal() {
            console.log('closeModal');
            this.active = false;
            this.countDown = false;
            this.resetTimer();
        },

        closeClerk() {
            // if (this.active == false) {
            //     console.log('return here')
            //     return
            // }

            console.log('closeClerk');
            if (this.$route.name == 'transaction'){
                console.log('transaction will not be closed');
                clearTimeout(this.logoutTimer);
                this.closeModal();
                return;
            }

            this.active = false;
            this.$store.dispatch('transaction/clear', null);
            this.$store.dispatch('transaction/setClerk', null);

            if (this.terminal.startpage === 'clerk.login') {
                this.$router.push({name: 'clerk.login'});
            }
            this.clearTimeouts();
        },
    },

    computed: {
		clerk() {
			return this.$store.getters['transaction/clerk'];
        },
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
    },
    watch: {
        countDown: {
            handler(value) {
                if(value > 0) {
                    setTimeout(() => {
                        console.log('countDown: ' + value);

                        if(this.active){
                            this.countDown--;
                        }
                    }, 1000);
                } else if(value <= 0 && this.active) {
                    this.closeClerk();
                }
            },
            immediate: true
        }
    },
};
</script>
