<template>
    <a @click="tableClose()" class="flex justify-center cursor-pointer button-anchor">
        <span v-if="terminal.layout == 'table'">{{$t('translations.components.table-close.close')}}</span>
        <span v-else>{{$t('translations.components.table-close.close-table')}}</span>
    </a>
</template>

<script>
import axios from 'axios'

export default {
    name: 'TableClose',
    data () {
        return {
            busy: false,
        }
    },
    methods: {
        async tableClose() {
            if (!this.transaction.table_id) {
                return
            }

            if (this.busy == true) {
                return;
            }

            if (this.transaction.lines.some(line => line.hourly_rate_item)) {
                const hourly_rate_index = this.transaction.lines.findIndex(line => line.hourly_rate_item == true);
                if (hourly_rate_index !== -1) {
                    this.transaction.lines.splice(hourly_rate_index, 1);
                    
                    this.$store.dispatch('transaction/setLines', this.transaction.lines);
                }
            }

            this.busy = true;

            // is clerk required
            if (this.terminal.clerk_required && !this.transaction.clerk_id) {
                this.$router.push({name: 'clerk.login'});
                return;
            }

            // set startpage
            let transaction = this.$store.getters['transaction/transaction'];
            transaction.lines = transaction.lines.filter(line => !line.defaultItem);

            // start payment
            await axios.post('/tables/' + this.transaction.table_id + '/close', transaction).then(() => {

                this.$notify({ group: "notifications", type: 'success', title: 'Tafel ' + this.transaction.table_name, text: "Tafel gesloten" }, 2000);
                this.$store.dispatch('transaction/clear')

                if (this.terminal.clerk_logout == 'direct') {
                    this.$store.dispatch('transaction/setClerk', null);
                }

            }).catch(() => {
                this.$store.dispatch('transaction/clear')

                this.$notify({ group: "notifications", type: 'error', title: "Mislukt", text: "Sluiten van de tafel" }, 2000);
            }).finally(() => {
                this.busy = false;

                const startpage = this.terminal.startpage

                if (startpage == 'tables') {
                    this.$router.push({name: 'pos.tables'})
                }
                else if (startpage == 'clerk.login') {
                    this.$router.push({name: 'clerk.login'})
                }
            })
        }
    },
	computed: {
		transaction() {
			return this.$store.getters['transaction/transaction'];
        },
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
    }
}
</script>
