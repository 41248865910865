<template>
    <div id="posretail" class="pos-wrapper flex flex-no-wrap h-full new-styling" :class="{'inverted' : terminal.inverted}">

        <div class="overview flex flex-grow flex-col justify-start h-full">

            <!-- searchbar -->
            <searchbar :group="selected_group" @search="search($event)" @levelup="selectGroupById(groups, $event)"/>

            <!-- retail view -->
            <div v-if="terminal.items_view == 'overview'" :class="[terminal.titles == 'bottom' ? 'categories-bottom' : 'categories-top']" class="tile-area-wrapper categories-bottom flex-grow overflow-y-auto overflow-x-hidden flex-grow">
                <div class="overflow-y-auto overflow-x-hidden scrollbar-hide">
                    <div class="tile-area" v-if="groups">
                        <div @click="selectGroup(child)"
                             v-for="(child, index_child) in selected_group.children" :key="index_child"
                             class="relative tile-outer group-child cursor-pointer p-1">

                            <div class="tile subcategory"
                                 :class="{'has-color': child.color || selected_group.color}"
                                 :style="[child.color ? {'border-color': child.color} : {'border-color': selected_group.color}]"
                            >
                                <!-- :style="{'background-color': group.color, 'border-color': group.color}"> -->
                                <div class="truncate font-semibold">{{ child.name }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tile-area">
                        <item :initialitem="item" view="button"
                              :parentcolor="selected_group.color"
                              :tilesize="terminal.buttonsize" :initialquantity="quantity"
                              @itemadded="handleItemAdded()" v-for="(item, index_items) in pos_visible(selected_group.items)"
                              :key="index_items" />
                        <!-- <div v-for="(item, index_items) in selected_group.items" :key="index_items"> -->
                        <!-- {{ item }} -->
                        <!-- </div> -->
                        <!-- {{selected_group.items}} -->
                    </div>
                </div>

                <div class="tile-area menu" :class="{'grid-rows-2': groups.length > 5}">
                    <div class="tile-outer menu" v-for="(group, index) in groups" :key="index">
                        <div @click="selectGroup(group)"
                             class="group border menu-tile border-gray-100 cursor-pointer overflow-hidden rounded-sm flex items-center justify-center"
                             :class="{'active': selected_group.id == group.id || selected_group.parent_id == group.id }" :style="{'border-color': group.color}">
                            <div class="truncate">{{ group.name }}</div>
                            <div class="active-border" :style="{'background-color': group.color}"></div>
                        </div>
                        <div class="menu-border"></div>
                    </div>
                </div>
            </div>

            <!-- columns view -->
            <div v-if="terminal.items_view == 'columns'" class="grid grid-cols-5 gap-2">
                <div v-for="(group, index) in groups" :key="index">
                    <div
                        class="group tile border border-gray-100 h-13 cursor-pointer overflow-hidden rounded-sm flex items-center justify-center"
                        :style="{'background-color': group.color, 'border-color': group.color}">
                        <div class="truncate font-semibold">{{ group.name }}</div>
                    </div>
                    <item :initialitem="item" view="button" :initialquantity="quantity" @itemadded="handleItemAdded()"
                          v-for="(item, index_items) in pos_visible(group.items)" :key="index_items" />
                </div>
            </div>

            <div class="flex gap-2 pt-2 transaction-functionalities bottom pr-4" v-if="terminal.extra_basket_functionalities_position === 'bottom'">
                <div class="button button-blur button-wrapper">
                    <modal-account view="button" />
                </div>
                <div @click="$refs.booking.open()" class="button button-blur button-wrapper">
                    <div class="flex justify-center">
                        {{$t('translations.views.pos.pos-retail.bookings')}}
                    </div>
                </div>
                <div @click="$refs.walletcheckcredit.open()" class="button button-blur button-wrapper">
                    <div class="flex justify-center">
                        {{$t('translations.views.pos.pos-retail.card-balance')}}
                    </div>
                </div>
                <div @click="$refs.walletaddcredit.open()" class="button button-blur button-wrapper">
                    <div class="flex justify-center">
                        {{$t('translations.views.pos.pos-retail.top-up-card')}}
                    </div>
                </div>
                <div @click="$refs.preordercollect.open()" class="button button-blur button-wrapper">
                    <div class="flex justify-center">
                        {{$t('translations.views.pos.pos-retail.pick-up-preorder')}}
                    </div>
                </div>
            </div>
        </div>

        <div class="transaction-area flex flex-col justify-between" :class="terminal.transaction_area_width">
            <div class="transaction-overview flex-grow overflow-y-hidden">
                <transaction layout="posretail" />
            </div>
            <div class="transaction-buttons-area">
                <div class="flex gap-2 pt-2 transaction-functionalities">
                    <div class="flex justify-between space-x-2 flex-grow items-stretch"
                         :class="{'max-width-transaction-functionalities': terminal.extra_basket_functionalities_position === 'in-transaction-area'}"
                    >
                            <!-- clear transaction -->
                            <span class="inline-flex rounded-sm w-full shadow-sm" @click="transactionClear()">
                            <a class="button button-blur square w-full">
                                <svg class="h-5 w-full" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd"
                                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                          clip-rule="evenodd" />
                                </svg>
                            </a>
                        </span>

                            <!-- line retour | admin only -->
                            <span v-if="terminal.admin_mode" class="inline-flex rounded-sm w-full shadow-sm">
                            <a @click="lineSelectedRetour()" class="button button-blur square w-full">
                                <svg class="h-5 w-full" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd"
                                          d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                          clip-rule="evenodd" />
                                </svg>
                            </a>
                        </span>

                        <!-- delete line -->
                        <span class="inline-flex rounded-sm w-full shadow-sm">
                            <a @click="lineSelectedDelete()" class="button button-blur square w-full">
                                <svg class="h-5 w-full" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                          clip-rule="evenodd" />
                                </svg>
                            </a>
                        </span>

                            <span class="inline-flex rounded-sm w-full shadow-sm">
                                <modal-discount />
                            </span>

                            <!-- open drawer -->
                            <span class="inline-flex rounded-sm w-full shadow-sm">
                            <a @click="drawerOpen()" class="button button-blur square w-full">
                                <svg class="h-5 w-full" fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z" />
                                    <path
                                        d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z" />
                                </svg>
                            </a>
                        </span>
                    </div>
                    <div class="transaction-bar-extra-functionalities-buttons" v-if="terminal.extra_basket_functionalities_position === 'in-transaction-area'">
                        <div class="grid grid-cols-2 gap-2 text-xs">
                            <div class="button button-blur button-wrapper">
                                <modal-account view="button" />
                            </div>
                            <div class="button button-blur button-wrapper">
                                <modal-functions view="button" />
                            </div>
                            <!-- <modal-booking view="button" /> -->
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-2 pt-2">
                    <div class="col-span-7">
                        <numpad ref="numpad" :times="true" @inputchanged="quantity = $event" />
                    </div>
                    <div class="col-span-5 flex flex-col">

                        <div class="col-span-1 button button-ghost button-wrapper mb-2">
                            <table-close v-if="transaction.table_id" />
                            <router-link :to="{name: 'pos.tables'}" class="flex justify-center cursor-pointer button-anchor" v-else>
                                {{$t('translations.views.pos.pos-retail.tables')}}
                            </router-link>
                        </div>

                        <div v-if="transaction.table_id" class="grid grid-cols-2 gap-2">
                            <div class="button button-ghost button-wrapper mb-2">
                                <modal-table-show />
                            </div>
                            <div class="button button-ghost button-wrapper mb-2">
                                <modal-table-history />
                            </div>
                        </div>
                        <div class="button button-ghost button-wrapper mb-2" v-else>
                            <modal-cashfunction />
                        </div>


                        <div v-if="transaction.table_id" @click="printReceiptTable()"
                             class="col-span-1 button button-ghost mb-2">
                            <div class="flex justify-center">
                                {{$t('translations.views.pos.pos-retail.bon-table')}}
                            </div>
                        </div>

                        <div v-if="!transaction.table_id" @click="printReceipt(terminal.last_transaction_id)"
                             class="col-span-1 button button-ghost mb-2">
                            <div class="flex justify-center">
                                {{$t('translations.views.pos.pos-retail.bon')}}
                            </div>
                        </div>

                        <!--            flex auto makes the button stretch to the rest of the column (height)-->
                        <div @click="gotoTransaction()"
                             class="button button-filled flex-auto flex items-center justify-center col-span-2 cursor-pointer p-2">
                             {{$t('translations.views.pos.pos-retail.pay')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- barcodescanner -->
        <barcode-scanner />
        <modal-subtotal ref="modalSubtotal" @selected="gotoTransaction()" />
        <modal-booking ref="booking" @opened="close()" />
        <modal-preorder-collect ref="preordercollect"  :allow-scanner="true" />
        <modal-wallet-add-credit ref="walletaddcredit"  :allow-scanner="true" />
        <modal-wallet-check-credit ref="walletcheckcredit"  :allow-scanner="true" />
    </div>
</template>

<style scoped>

</style>

<script>

// mixins
import PosHelper from '../../mixins/pos.helper.js';

// components
import BarcodeScanner from '../../components/BarcodeScanner';
import TableClose from '../../components/TableClose';
import ModalAccount from '../../components/ModalAccount';
import ModalFunctions from "../../components/ModalFunctions";
import ModalCashfunction from '../../components/ModalCashfunction';
import ModalTableHistory from '../../components/ModalTableHistory';
import ModalTableShow from '../../components/ModalTableShow';
import Numpad from '../../components/Numpad';
import ModalDiscount from "../../components/ModalDiscount";
import ModalBooking from '../../components/ModalBooking';
import ModalPreorderCollect from '../../components/ModalPreorderCollect.vue';
import ModalWalletAddCredit from '../../components/ModalWalletAddCredit.vue';
import ModalWalletCheckCredit from '../../components/ModalWalletCheckCredit.vue';


export default {
    name: 'PosDefault',
    mixins: [PosHelper],
    components: {
        BarcodeScanner,
        TableClose,
        ModalAccount,
        ModalCashfunction,
        ModalFunctions,
        ModalTableHistory,
        ModalTableShow,
        Numpad,
        ModalDiscount,
        ModalBooking,
        ModalPreorderCollect,
        ModalWalletCheckCredit,
        ModalWalletAddCredit,
    },

    mounted () {
        // check if there is a default table
        this.setDefaultTable();
    },

    data () {
        return {
            quantity: null,
        };
    },
    methods: {

        transactionClear () {
            this.$store.dispatch('transaction/clear', this.quantity);

            // is table required => return to tables page
            if (this.terminal.startpage == 'tables') {
                this.$router.push({ name: 'pos.tables' });
            }

            this.setDefaultTable();
        },

        handleItemAdded () {
            this.clearInput();
        },

        clearInput () {
            this.$refs.numpad.clearInput();
        },
    },
	computed: {
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
    }
};
</script>
