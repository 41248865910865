<template>

    <!--  <div style="display: flex; overflow-x: scroll; justify-content: flex-start; flex-direction: column-reverse; max-width: 100px; height: 300px; border: 2px solid yellow;">-->
    <!--    <div class="" style="padding: 10px; text-align: left;">Hallo</div>-->
    <!--    <div class="" style="padding: 10px; text-align: left;">Hallo Meneer</div>-->
    <!--    <div class="" style="padding: 10px; text-align: left;">Hallo Mevrouw</div>-->

    <!--  </div>-->

    <div id="posevents" class="pos-wrapper flex flex-no-wrap h-full" :class="{'inverted' : terminal.inverted}">
        <div class="overview flex flex-grow flex-col justify-start h-full">

            <!-- searchbar -->
            <searchbar :group="selected_group" @search="search($event)" @levelup="selectGroupById(groups, $event)" />


            <!-- retail view -->
            <div v-if="terminal.items_view == 'overview'" :class="[terminal.titles == 'bottom' ? 'categories-bottom' : 'categories-top']"
                 class="tile-area-wrapper categories-bottom flex-grow overflow-y-auto overflow-x-hidden flex-grow">
                <div class="overflow-y-auto overflow-x-hidden scrollbar-hide">
                    <div class="tile-area" v-if="groups">
                        <div @click="selectGroup(child)" v-for="(child, index_child) in selected_group.children"
                             :key="index_child" class="relative tile-outer group-child">

                            <div class="tile subcategory"
                                 :class="{'has-color': child.color || selected_group.color}"
                                 :style="[child.color ? {'border-color': child.color} : {'border-color': selected_group.color}]"
                            >
                                <!-- :style="{'background-color': group.color, 'border-color': group.color}"> -->
                                <div class="truncate">{{ child.name }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="tile-area" v-if="groups">
                        <item :initialitem="item" view="button"
                              :parentcolor="selected_group.color"
                              :tilesize="terminal.buttonsize" :initialquantity="quantity"
                              v-for="(item, index_items) in selected_group.items" :key="index_items" />
                        <!-- <div v-for="(item, index_items) in selected_group.items" :key="index_items"> -->
                        <!-- {{ item }} -->
                        <!-- </div> -->
                        <!-- {{selected_group.items}} -->
                    </div>
                </div>

                <div class="tile-area menu">
                    <div class="tile-outer menu" v-for="(group, index) in groups" :key="index">
                        <div @click="selectGroup(group)" class="group menu-tile border flex items-center justify-center"
                             :class="{'active': selected_group.id == group.id || selected_group.parent_id == group.id}" :style="{'border-color': group.color}">
                            <div class="truncate">{{ group.name }}</div>
                            <div class="active-border" :style="{'background-color': group.color}"></div>
                        </div>
                        <div class="menu-border"></div>
                    </div>
                </div>
            </div>

            <!-- columns view -->
            <div v-if="terminal.items_view == 'columns'" class="grid grid-cols-5 gap-4">
                <div class="grid col-span-4 grid-cols-4">
                    <div v-for="(group, index) in groups" :key="index">
                        <div
                            class="group border border-gray-100 h-13 cursor-pointer overflow-hidden rounded-sm flex items-center justify-center"
                            :style="{'background-color': group.color, 'border-color': group.color}">
                            <div class="truncate font-semibold">{{ group.name }}</div>
                        </div>
                        <item :initialitem="item" view="button" :initialquantity="quantity"
                              v-for="(item, index_items) in group.items" :key="index_items" />
                    </div>
                </div>
            </div>

            <div class="flex gap-2 pt-2 transaction-functionalities bottom pr-4" v-if="terminal.extra_basket_functionalities_position === 'bottom'">
                <div @click="$refs.walletcheckcredit.open()" class="button button-blur button-wrapper">
                    <div class="flex justify-center">
                        {{$t('translations.views.pos.pos-events.card-balance')}}?
                    </div>
                </div>
                <div @click="$refs.walletaddcredit.open()" class="button button-blur button-wrapper">
                    <div class="flex justify-center">
                        {{$t('translations.views.pos.pos-events.top-up-card')}}
                    </div>
                </div>
                <div @click="$refs.customers.open()" class="button button-blur button-wrapper">
                    <div class="flex justify-center">
                        {{$t('translations.views.pos.pos-events.customers')}}
                    </div>
                </div>
            </div>
        </div>

        <div class="transaction-area flex flex-col justify-between" :class="terminal.transaction_area_width">

            <div class="transaction-overview flex-grow overflow-y-hidden">
                <transaction :layout="posevents" />
            </div>

            <div class="transaction-buttons-area">
                <!-- actions -->
                <div class="grid grid-cols-4 gap-2 pb-2 transaction-functionalities">
                    <div @click="gotoTransaction()" class="col-span-2 button button-ghost">
                        {{$t('translations.views.pos.pos-events.pay')}}
                    </div>

                    <div @click="printReceipt(terminal.last_transaction_id)" class="col-span-1 button button-ghost">
                        {{$t('translations.views.pos.pos-events.bon')}}
                    </div>

                    <div @click="transactionClear()" class="col-span-1 button button-ghost square">
                        <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd"
                                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                  clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>

                <div v-if="paymenttype">
                    <div class="w-full">
                        <a @click="gotoTransaction(paymenttype.id)"
                           class="button button-filled flex items-center justify-center w-full">
                            {{ paymenttype.attributes.name }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <modal-subtotal ref="modalSubtotal" @selected="gotoTransaction()" />
        <modal-wallet-add-credit ref="walletaddcredit" :allow-scanner="true" />
        <modal-wallet-check-credit ref="walletcheckcredit" :allow-scanner="true" />
        <modal-customers ref="customers" :allow-scanner="true" />
    </div>
</template>

<style scoped>

</style>

<script>

// mixins
import PosHelper from '../../mixins/pos.helper.js';

import ModalWalletAddCredit from '../../components/ModalWalletAddCredit.vue';
import ModalWalletCheckCredit from '../../components/ModalWalletCheckCredit.vue';
import ModalCustomers from '../../components/ModalCustomers.vue';

export default {
    name: 'PosEvents',
    mixins: [PosHelper],
    components: {
        ModalWalletCheckCredit,
        ModalWalletAddCredit,
        ModalCustomers,
    },
    mounted () {
        this.getPerferredPaymenttype();

        // check if there is a default table
        this.setDefaultTable();
    },
	computed: {
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
    }
};
</script>
