<template>
    <div v-if="!isMobile" class="button-anchor">
        <a class="w-full flex justify-center cursor-pointer" @click="open()">
            {{$t('translations.components.modal-table-show.placeholder')}}
        </a>
    </div>

    <div v-if="isMobile" class="button-primary-outline rounded shadow flex items-center justify-center" @click="open()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
        </svg>
    </div>

    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto mobile-large">
        <div class="flex items-center justify-center h-screen text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal"
                 class="modal-extra-large modal-large-padding modal-mobile-styles h-screen sm:h-auto w-screen inline-block align-bottom sm:rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content table-move h-full">

                    <modal-close @close="close()" />

                    <div class="flex flex-col h-full overflow-hidden">
                        <div class="p-3 sm:p-0 text-white flex items-center justify-between mb-5 mobile-modal-title">
                            <div class="sm:mt-0 sm:text-left flex justify-between w-full pr-2 sm:pr-12">
                                <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                    {{$t('translations.components.modal-table-show.table')}}
                                    {{ table.attributes.name }}
                                    <span class="ml-1 font-normal" v-if="table.attributes.name_temporary">({{ table.attributes.name_temporary}})</span>
                                </h3>
                                <div class="flex items-center mb-4 font-bold">
                                    <div class="text-sm mr-2 capitalize hidden sm:block">{{ table_view }}</div>
                                    <button @click="toggleView" type="button"
                                            :class="[table_view === 'consolidated' ? 'bg-sjef-purple' : 'bg-sjef-green']"
                                            class="bg-sjef-green relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            role="switch">
                                        <span :class="[table_view === 'mutations' ? 'translate-x-5' : 'translate-x-0']"
                                              class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div v-if="table.relationships.lines.length == 0"
                             class=" mobile-table-show mobile-fill-scroll-wrapper" style="height:350px">
                             {{$t('translations.components.modal-table-show.no-items')}}
                        </div>

                        <div v-else class="flex-grow overflow-y-auto scrollbar-hide">
                            <div class="mobile-table-show mobile-fill-scroll-wrapper scrollbar-hide">

                                <div v-if="table_view === 'mutations'">

                                    <div class="py-1 px-8 grid grid-cols-12 mt-1 mb-2 mobile-table-top-bar">
                                        <div class="col-span-1"></div>
                                        <div class="col-span-2 font-bold">{{$t('translations.components.modal-table-show.number-of')}}</div>
                                        <div class="col-span-7 sm:col-span-5 font-bold">{{$t('translations.components.modal-table-show.description')}}</div>
                                        <div class="col-span-2 font-bold ">{{$t('translations.components.modal-table-show.time-date')}}</div>
                                        <div class="col-span-2 font-bold text-right">{{$t('translations.components.modal-table-show.total')}}</div>
                                    </div>

                                    <div v-for="(line, index) in table.relationships.lines.slice().reverse()"
                                         :key="index"
                                         class="list-item grid grid-cols-12 mb-1 items-center"
                                         :class="getClassSplitted(line, table.relationships.lines.slice().reverse(), index)"
                                    >

                                        <div class="col-span-1 remove opacity-0 pointer-events-none">
                                            <a @click="subQuantity(line)" v-if="line.attributes.quantity > 0">
                                                <svg width="25" height="25" viewBox="0 0 20 20" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H7Z"
                                                          fill="currentColor" />
                                                </svg>
                                            </a>
                                        </div>
                                        <div class="col-span-2 flex justify-start amount">
                                            <div :class="{'line-through': line.attributes.quantity_subtract}">
                                                {{ parseFloat(line.attributes.quantity) }}
                                            </div>
                                            <div class="ml-3 font-bold" v-if="line.attributes.quantity_subtract">
                                                {{  parseFloat(parseFloat(line.attributes.quantity) - parseFloat(line.attributes.quantity_subtract)) }}
                                            </div>
                                        </div>
                                        <div class="col-span-7 sm:col-span-5 truncate">{{
                                                line.attributes.description
                                            }}
                                        </div>
                                        <div class="hidden sm:block col-span-2 date-time">{{
                                                line.attributes.formatted_created_at
                                            }}
                                        </div>
                                        <div class="col-span-2 text-right">{{
                                                vueNumberFormat(line.attributes.total)
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div v-if="table_view === 'consolidated'">
                                    <div class="py-1 px-8 grid grid-cols-12 mt-1 mb-2 mobile-table-top-bar">
                                        <div class="col-span-1"></div>
                                        <div class="col-span-2 font-bold">{{$t('translations.components.modal-table-show.number-of')}}</div>
                                        <div class="col-span-7 sm:col-span-5 font-bold">{{$t('translations.components.modal-table-show.description')}}</div>
                                        <div class="col-span-2 font-bold text-right">{{$t('translations.components.modal-table-show.total')}}</div>
                                    </div>

                                    <div v-for="(line, index) in table.relationships.lines_consolidated.slice()"
                                         :key="index"
                                         class="list-item grid grid-cols-12 mb-1 items-center">


                                        <div class="col-span-1 remove">
                                        <!-- <div class="col-span-1 remove" :class="{'opacity-0 pointer-events-none': line.attributes.parent_id}"> -->
                                            <a @click="subQuantity(line, true)" v-if="line.attributes.quantity > 0">
                                                <svg width="25" height="25" viewBox="0 0 20 20" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H7Z"
                                                          fill="currentColor" />
                                                </svg>
                                            </a>
                                        </div>
                                        <div class="col-span-2 flex justify-start amount">
                                            <div :class="{'line-through': line.attributes.quantity_subtract}">
                                                {{ parseFloat(line.attributes.quantity) }}
                                            </div>
                                            <div class="ml-3 font-bold" v-if="line.attributes.quantity_subtract">
                                                {{  parseFloat(parseFloat(line.attributes.quantity) - parseFloat(line.attributes.quantity_subtract)) }}
                                            </div>
                                        </div>
                                        <div class="col-span-7 sm:col-span-5">
                                            <div>{{ line.attributes.description }}</div>
                                            <div class="text-sm mt-2" v-if="line.attributes.notes">{{ line.attributes.notes }}</div>
                                            <div class="text-sm mt-2" v-if="line.attributes.kitchen_groceries">
                                                <div class="mt-2" v-for="(line, index) in line.attributes.kitchen_groceries" :key="index">
                                                    {{ line }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-span-2 text-right">{{
                                                vueNumberFormat(line.attributes.total)
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer sm:flex justify-between items-center p-0 sm:p-6">
                            <div class="flex justify-start items-center">
                                <div class="col-span-10 text-right font-bold text-xl mr-4">{{$t('translations.components.modal-table-show.total')}}:</div>
                                <div class="col-span-2 text-right font-bold text-xl">
                                    {{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}
                                </div>
                            </div>
                            <div class="flex buttons mt-2 sm:mt-0">
                                <router-link
                                    :to="{name: 'pos.tables.move'}"
                                    class="button button-ghost w-full mr-3">
                                    {{$t('translations.components.modal-table-show.move-table')}}
                                </router-link>
                                <a v-if="table.relationships.lines.length > 0"
                                   @click="tableSave()" class="button button-filled w-full">
                                    {{$t('translations.components.modal-table-show.save')}}
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import ModalClose from './ModalClose.vue';
// print

export default {
    emits: ['reload'],
    components: {
        ModalClose,
    },
    data () {
        return {
            active: false,
            table: null,
            table_consolidated: null,
            table_view: 'consolidated',
            lines_removed: [],
        };
    },
    methods: {
        open () {
            this.tableGet();
        },

        close () {
            this.lines_removed = [];
            this.active = false;
        },

        toggleView () {
            if (this.table_view === 'mutations') {
                this.table_view = 'consolidated';
            } else if (this.table_view === 'consolidated') {
                this.table_view = 'mutations';
            }
        },

        getClassSplitted (line, lines, index) {
            if (index === 0) {
                return 'splitted first';
            }

            if (index > 0) {
                if (lines[index].attributes.formatted_created_at !== lines[index - 1].attributes.formatted_created_at) {
                    return 'splitted';
                }
            }

            return '';
        },

        async tableGet () {
            axios.get('/tables/' + this.transaction.table_id).then(response => {
                this.table = response.data.data;
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => {
                this.active = true;
            });
        },

        async tableSave () {
            if (this.lines_removed.length > 0) {
                let lines = [];

                this.lines_removed.forEach(function(elem) {
                    lines.push({
                        id: elem.id,
                        item_id: elem.attributes.item_id,
                        parent_id: elem.attributes.parent_id || null,
                        quantity: parseFloat(parseFloat(elem.attributes.quantity_subtract) * -1),
                        rate: elem.attributes.rate,
                        rate_without_discount: elem.attributes.rate,
                        cashfunction_id: elem.attributes.cashfunction_id || null,
                        description: elem.attributes.description || null,
                        taxrate_id: elem.attributes.taxrate_id || null,
                        variant_label: elem.attributes.variant_label || null,
                        addon: elem.attributes.addon || null,
                        kitchen_groceries: elem.attributes.kitchen_groceries || null,
                        notes: elem.attributes.notes || null,
                    })
                });

                await axios.post('/tables/' + this.table.id + '/close', {
                        lines: lines,
                        table_show: true,
                    }).then(() => {
                    this.$notify({ group: "notifications", type: 'success', title: 'Tafel ' + this.transaction.table_name, text: "Mutatie aangemaakt" }, 1000);
                    // this.$store.dispatch('transaction/clear');

                    this.$emit('reload');
                    this.close();

                }).catch(() => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Mislukt",
                        text: "Kan mutatie niet voltooien.",
                    }, 2000);
                }).finally(() => {
                });
            } else {
                this.close();
            }
        },

        subQuantity (line) {
            let quantity = line.attributes.quantity_subtract;
            if (quantity) {
                quantity = quantity + 1;
            } else {
                quantity = 1;
            }

            if (quantity <= line.attributes.quantity) {
                line.attributes.quantity_subtract = quantity;

                if (!this.lines_removed.filter(item => item.id == line.id).length) {
                    this.lines_removed.push(line);
                }

                let childLines = this.table.relationships.lines_consolidated.filter(consolidated_line => consolidated_line.attributes.parent_id == line.id);

                let self = this;
                childLines.forEach(function(childLine) {
                    childLine.attributes.quantity_subtract = line.attributes.quantity_subtract;

                    if(!self.lines_removed.includes(childLine)) {
                        self.lines_removed.push(childLine);
                    }
                })
            }
        },
    },
    computed: {
        transaction () {
            return this.$store.getters['transaction/transaction'];
        },

        isMobile () {
            return this.$store.getters['terminal/isMobile'];
        },
    },
};
</script>
