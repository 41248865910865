<template>
    <div v-if="view == 'button'" @click="open()" class="col-span-1 button-anchor">
        <div class="flex justify-between">
            {{$t('translations.components.modal-wallettoken-connect.title')}}
        </div>
    </div>

    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal" class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">

                    <modal-close @close="close()"/>

                    <div>
                        <div class="flex justify-between items-center mb-5">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                    <div>{{$t('translations.components.modal-wallettoken-connect.scan-token')}}</div>
                                </h3>
                            </div>
                        </div>

                        <div class="token-scan-steps">
                            <div class="step">
                                {{$t('translations.components.modal-wallettoken-connect.step')}}
                                <span class="step-counter">
                                    {{ step }}
                                </span>
                            </div>
                            <div class="info">
                                <div v-if="step === 1">
                                    {{$t('translations.components.modal-wallettoken-connect.scan-token')}}
                                </div>
                                <div v-else-if="step === 2">
                                    {{$t('translations.components.modal-wallettoken-connect.scan-again')}}
                                </div>
                                <div v-if="connection && connection.data.connected === true" class="token-connected">
                                    <svg width="270px" height="270px" viewBox="0 0 270 270" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                         xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="Ellipse-2" transform="translate(4.000000, 4.000000)" stroke="#FFF200">
                                                <circle id="Oval" stroke-width="8" cx="131" cy="131" r="131"></circle>
                                                <path
                                                    d="M78,126.7027 L116.7947,166.255 C117.1931,166.6612 117.8497,166.6536 118.2386,166.2383 L184,96"
                                                    id="Path" stroke-width="10" stroke-linecap="round"></path>
                                            </g>
                                        </g>
                                    </svg>
                                    {{$t('translations.components.modal-wallettoken-connect.token-linked')}}
                                </div>
                            </div>
                        </div>

                        <div v-if="step === 1" class="mt-10 flex items-center font-bold">
                            <span @click="toggleCardType()" type="button"
                                    :class="[card_scan_type === 'cardnumber' ? 'bg-sjef-purple' : 'bg-sjef-green']"
                                    class="bg-sjef-green relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    role="switch">
                                <span :class="[card_scan_type === 'chipcard_id' ? 'translate-x-5' : 'translate-x-0']"
                                      class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                            </span>
                            <div class="text-sm ml-2 capitalize hidden sm:block">{{ card_scan_type }}</div>
                        </div>
                    </div>
                </div>

                <div v-if="connection && connection.data.connected === true" class="modal-footer flex justify-end">
                    <div class="button button-filled" @click="$emit('close'); close()">
                        {{$t('translations.components.modal-wallettoken-connect.close')}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import md5 from 'js-md5'
import BarcodeReader from "../mixins/barcode.reader";

// components
import ModalClose from "./ModalClose";

export default {
    name: 'ModalWallettokenConnect',
    emits: ['opened'],
    mixins: [BarcodeReader],

    components: {
        ModalClose,
    },
    mounted () {
    },
    data () {
        return {
            customer_id: '',
            wallet_id: '',
            active: false,
            connection: '',
            card_scan_type: 'cardnumber',
            step: 1,
            barcode: {
                1: '',
                2: '',
            },
        }
    },

    methods: {

        toggleCardType () {
            if (this.card_scan_type === 'cardnumber') {
                this.card_scan_type = 'chipcard_id';
            } else if (this.card_scan_type === 'chipcard_id') {
                this.card_scan_type = 'cardnumber';
            }
        },

        open(customer_id, wallet_id) {

            this.customer_id = customer_id;
            this.wallet_id = wallet_id;

            // disable item barcodescanner
            this.$store.dispatch('settings/itemscanner', false)

            this.card_scan_type = this.terminal.wallettoken_default_scan;
            this.active = true;

            // todo: remove for production
            // this.barcodeScanned(process.env.VUE_APP_WALLETTOKEN)
            this.$store.dispatch('terminal/setModalActive', true);

        },

        close() {
            // activate item barcodescanner
            this.$store.dispatch('settings/itemscanner', false)
            this.$store.dispatch('terminal/setModalActive', false);

            this.active = false
        },

        // search item on barcode scanner
        processBarcodeScan(barcode) {

            if (barcode.length == 0) {
                return;
            }

            this.barcode[this.step] = barcode;

            if (this.step === 2 && this.barcode[1] != this.barcode[2]) {
                this.$notify({ group: "notifications", type: 'error', title: "Ongeldig", text: 'Barcode is different' }, 2000);
            }

            this.connectWallettoken();
        },

        barcodeScanned(barcode) {
            this.processBarcodeScan(barcode);
        },

        async connectWallettoken() {
            // todo loading spinner

            let data = {
                customer_id: this.customer_id,
                wallet_id: this.wallet_id,
                connect: this.step === 2,
            };

            data[this.card_scan_type] = this.barcode[this.step];

            await axios.post('/wallettokens/connect', data).then(response => {
                if (response.data.result == 'success') {
                    this.connection = response;
                    this.step++;
                    // this.card = response.data
                    // this.page = 'result'
                } else {
                    this.$notify({ group: "notifications", type: 'error', title: "Ongeldig", text: response.data.message }, 2000);
                }
            }).catch(error => {
                if (error.response.status == 404) {
                    this.$notify({ group: "notifications", type: 'error', title: "Niet gevonden", text: "Gescande artikel is niet gevonden" }, 2000);
                } else {
                    this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                    console.error(error)
                }
            })
        },

        submit() {
            if (!this.card) {
                return
            }

            if (this.amount == '') {
                this.$notify({ group: "notifications", type: 'error', title: "Ongeldig", text: "Geef een bedrag op" }, 2000);
            }
            let data = {
                cashfunction_id: 1,//this.cashfunction.id,
                wallettoken_id: this.card.id,
                type: 'cashinout',
                parent_id: null,
                item_id: null,
                item_parent_id: null,
                addon: null,
                editable: true,
                composed_child: false,
                quantity: 1,
                quantity_init: 1,
                barcode: '',
                description: 'Opwaarderen', //this.cashfunction.attributes.description,
                kitchen_groceries: [],
                taxrate: 0,
                rate: this.amount,
                rate_without_discount: this.amount,
                discount_amount: 0,
                total: this.amount,//.toFixed(2),
                variant_id:  null,
                variant_label:  null,
                notes:  null,
            }

            data.id = md5(JSON.stringify(data))

            this.$store.dispatch('transaction/addItem', data)

            this.close()
        },

        async setCashfunctions() {
            axios.get('/cashfunctions').then(response => {
                this.cashfunctions = response.data.data
            }).catch(error => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                console.error(error)
            })
        },
    },
    computed: {
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
    }
}
</script>
