<template>
    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal"
                 class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">
                    <modal-close @close="close()" />
                    <div class="flex justify-between items-center">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                {{$t('translations.components.modal-customer-functions.customer-features')}}
                            </h3>
                        </div>
                    </div>

                    <div class="customer-info">
                        <div>{{ customer.attributes.firstname }}</div>
                        <div>{{ customer.attributes.email }}</div>
                        <div>{{ customer.attributes.searchquery }}</div>
                    </div>

                    <!-- {{ customer.relationships }} -->
                    <div class="customer-tokens-wrapper">
                        <div class="customer-token" v-for="(wallet, index) in customer.relationships.wallets.data" :key="index">
                            <div class="text-lg font-bold">{{ wallet.attributes.description}}</div>
                            <div class="overflow-x-auto">
                                <div @click="$refs.wallettokenconnect.open(customer.id, wallet.id)"
                                    class="w-full button button-ghost flex items-center justify-center truncate">
                                    <div class="flex justify-center">
                                        {{$t('translations.components.modal-customer-functions.link-token')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-wallettoken-connect @close="$emit('close'); close()" ref="wallettokenconnect" />
    </div>

</template>

<script>
import ModalWallettokenConnect from './ModalWallettokenConnect';
import ModalClose from './ModalClose.vue';

export default {
    props: ['view'],
    components: {
        ModalWallettokenConnect,
        ModalClose,
    },
    data () {
        return {
            active: false,
            customer: {},
        };
    },

    methods: {
        open (customer) {
            this.customer = customer;
            this.active = true;
        },

        close () {
            this.active = false;
        },
    },
};
</script>
