<template>
    <modal-manager-login ref="managerlogin" @loggedin="showModal()" />

    <div @click="open()" class="col-span-1 button-anchor">
        {{$t('translations.components.modal-cash-function.title')}}
    </div>

    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal" class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

                 <div class="modal-content">

                   <modal-close @close="close()"/>

                    <!-- select cashfunction -->
                    <div v-if="page == 'select'">
                        <div class="flex justify-between items-center">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                    {{$t('translations.components.modal-cash-function.cash-drawer')}}
                                </h3>
                            </div>
                        </div>
                        <ul>
                            <li @click="select(cashfunction)" v-for="(cashfunction, index) in cashfunctions" :key="index" class="cursor-pointer col-span-1 mt-4">
                                <div class="w-full button button-blur button-large flex items-center justify-center p-6 space-x-6 truncate">
                                    <div class="text-center">
                                        <h3 class="mb-3 text-lg leading-5 font-medium truncate">{{ cashfunction.attributes.description }}</h3>
                                        <div class="text-sm">{{ cashfunction.attributes.direction }} / {{ cashfunction.attributes.type }}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- confirm cashfunction -->
                    <div v-else>
                        <div class="flex justify-between items-center mb-5">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                    {{ cashfunction.attributes.description }}
                                </h3>
                                <div class="text-gray-500 text-sm">{{ cashfunction.attributes.direction }} / {{ cashfunction.attributes.type }}</div>
                            </div>
                        </div>
                        <numpad :formatted="true" @inputchanged="amount = parseFloat($event / 100)" />
                    </div>
                </div>
                <div class="modal-footer" v-if="cashfunction?.id">
                    <a @click="submit()" class="button button-large button-filled">
                        {{$t('translations.components.modal-cash-function.confirm')}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import md5 from 'js-md5'

// components
import ModalClose from './ModalClose.vue';
import ModalManagerLogin from './ModalManagerLogin.vue';

import Numpad from './Numpad';

export default {
    components: {
        ModalClose,
        ModalManagerLogin,
        Numpad,
    },
    data () {
        return {
            active: false,
            page: 'select',
            amount: null,
            cashfunction: null,
            cashfunctions: [],
        }
    },

    methods: {
        open() {
            this.$refs.managerlogin.open()
        },
        showModal() {
            this.setCashfunctions();
            this.page = 'select'
            this.cashfunction = null
            this.active = true
            this.amount = ''
        },

        close() {
            this.active = false
        },

        select(cashfunction) {
            this.cashfunction = cashfunction

            if (this.cashfunction.attributes.type == 'fixed') {
                this.amount = this.cashfunction.attributes.amount
                this.submit()
            } else {
                this.page = 'amount'
            }
        },


        submit() {
            if (!this.cashfunction) {
                return
            }

            if (this.amount == '') {
                this.$notify({ group: "notifications", type: 'error', title: "Ongeldig", text: "Geef een bedrag op" }, 2000);
            }
            if (this.cashfunction.attributes.direction == 'out') {
                this.amount = this.amount * -1
            }

            let data = {
                cashfunction_id: this.cashfunction.id,
                type: 'cashinout',
                parent_id: null,
                item_id: null,
                item_parent_id: null,
                addon: null,
                editable: true,
                composed_child: false,
                quantity: 1,
                quantity_init: 1,
                barcode: '',
                description: this.cashfunction.attributes.description,
                kitchen_groceries: [],
                taxrate: 0,
                rate: this.amount,
                rate_without_discount: this.amount,
                discount_amount: 0,
                total: this.amount,//.toFixed(2),
                variant_id:  null,
                variant_label:  null,
                notes:  null,
            }

            data.id = md5(JSON.stringify(data))

            this.$store.dispatch('transaction/addItem', data)

            this.close()
        },

        async setCashfunctions() {
            axios.get('/cashfunctions').then(response => {
                this.cashfunctions = response.data.data
            }).catch(error => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                console.error(error)
            })
        },
    },
}
</script>
