<template>
    <a v-if="view == 'button'" @click="open()" class="col-span-1 button-anchor">
        <div class="flex justify-between">
            {{$t('translations.components.modal-customers.placeholder')}}
        </div>
    </a>
    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal"
                 class="inline-block modal modal-extra-large align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">
                    <modal-close @close="close()" />

                    <div class="sm:flex sm:items-start mb-5">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl mb-1" id="modal-headline">
                                {{$t('translations.components.modal-customers.title')}}
                            </h3>
                        </div>
                    </div>

                    <div>
                        <div class="flex gap-4">
                            <div @click="newSearch('reference')"
                                 class="w-full button button-blur button-large flex items-center justify-center p-6 truncate">
                                <div class="flex justify-center">
                                    {{$t('translations.components.modal-customers.customer-reference')}}
                                </div>
                            </div>
                            <div @click="newSearch('searchquery')"
                                 class="w-full button button-blur button-large flex items-center justify-center p-6 truncate">
                                <div class="flex justify-center">
                                    {{$t('translations.components.modal-customers.search-name')}}
                                </div>
                            </div>
                        </div>

                        <div class="pt-6">
                            <div v-if="searchBy === 'reference'  && searched === false">
                                <h1 class="font-bold mw-pos mx-auto text-3xl text-center mt-4">
                                    {{$t('translations.components.modal-customers.qrcode')}}
                                </h1>

                                <div class="qr-svg-wrapper">
                                    <svg id="Layer_1" class="qr-svg" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 512 512">
                                    <title>{{$t('translations.components.modal-customers.qrcode-outline')}}</title>
                                    <path class="cls-1"
                                          d="M32,153.6h89.6a32,32,0,0,0,32-32V32a32,32,0,0,0-32-32H32A32,32,0,0,0,0,32v89.6A32,32,0,0,0,32,153.6ZM25.6,32A6.41,6.41,0,0,1,32,25.6h89.6A6.41,6.41,0,0,1,128,32v89.6a6.41,6.41,0,0,1-6.4,6.4H32a6.41,6.41,0,0,1-6.4-6.4Z" />
                                    <rect class="cls-1" x="51.2" y="51.2" width="51.2" height="51.2" />
                                    <path class="cls-1"
                                          d="M121.6,358.4H32a32,32,0,0,0-32,32V480a32,32,0,0,0,32,32h89.6a32,32,0,0,0,32-32V390.4A32,32,0,0,0,121.6,358.4ZM128,480a6.41,6.41,0,0,1-6.4,6.4H32a6.41,6.41,0,0,1-6.4-6.4V390.4A6.41,6.41,0,0,1,32,384h89.6a6.41,6.41,0,0,1,6.4,6.4Z" />
                                    <rect class="cls-1" x="51.2" y="409.6" width="51.2" height="51.2" />
                                    <path class="cls-1"
                                          d="M480,0H390.4a32,32,0,0,0-32,32v89.6a32,32,0,0,0,32,32H480a32,32,0,0,0,32-32V32A32,32,0,0,0,480,0Zm6.4,121.6A6.41,6.41,0,0,1,480,128H390.4a6.41,6.41,0,0,1-6.4-6.4V32a6.41,6.41,0,0,1,6.4-6.4H480a6.41,6.41,0,0,1,6.4,6.4Z" />
                                    <rect class="cls-1" x="409.6" y="51.2" width="51.2" height="51.2" />
                                    <polygon class="cls-1"
                                             points="204.8 25.6 230.4 25.6 230.4 51.2 256 51.2 256 0 243.2 0 230.4 0 179.2 0 179.2 12.8 179.2 25.6 179.2 51.2 204.8 51.2 204.8 25.6" />
                                    <rect class="cls-1" x="307.2" width="25.6" height="25.6" />
                                    <polygon class="cls-1"
                                             points="204.8 128 230.4 128 230.4 102.4 204.8 102.4 204.8 76.8 179.2 76.8 179.2 179.2 204.8 179.2 204.8 128" />
                                    <polygon class="cls-1"
                                             points="332.8 102.4 332.8 51.2 307.2 51.2 307.2 76.8 230.4 76.8 230.4 102.4 307.2 102.4 332.8 102.4" />
                                    <rect class="cls-1" x="230.4" y="153.6" width="51.2" height="25.6" />
                                    <polygon class="cls-1"
                                             points="307.2 128 307.2 179.2 307.2 204.8 384 204.8 384 179.2 332.8 179.2 332.8 128 307.2 128" />
                                    <polygon class="cls-1"
                                             points="460.8 204.8 486.4 204.8 486.4 230.4 512 230.4 512 204.8 512 179.2 460.8 179.2 460.8 204.8" />
                                    <rect class="cls-1" x="409.6" y="179.2" width="25.6" height="51.2" />
                                    <polygon class="cls-1"
                                             points="358.4 332.8 384 332.8 384 230.4 358.4 230.4 358.4 256 332.8 256 332.8 230.4 307.2 230.4 307.2 256 307.2 281.6 332.8 281.6 358.4 281.6 358.4 332.8" />
                                    <rect class="cls-1" x="256" y="256" width="25.6" height="25.6" />
                                    <polygon class="cls-1"
                                             points="486.4 281.6 460.8 281.6 460.8 256 435.2 256 435.2 281.6 409.6 281.6 409.6 307.2 435.2 307.2 460.8 307.2 486.4 307.2 512 307.2 512 256 486.4 256 486.4 281.6" />
                                    <rect class="cls-1" x="76.8" y="179.2" width="25.6" height="25.6" />
                                    <polygon class="cls-1"
                                             points="51.2 281.6 51.2 256 51.2 230.4 76.8 230.4 76.8 204.8 25.6 204.8 25.6 179.2 0 179.2 0 230.4 25.6 230.4 25.6 256 0 256 0 281.6 25.6 281.6 51.2 281.6" />
                                    <polygon class="cls-1"
                                             points="102.4 307.2 140.8 307.2 140.8 281.6 76.8 281.6 76.8 307.2 0 307.2 0 332.8 102.4 332.8 102.4 307.2" />
                                    <polygon class="cls-1"
                                             points="230.4 307.2 230.4 256 230.4 230.4 256 230.4 256 204.8 204.8 204.8 204.8 230.4 153.6 230.4 153.6 179.2 128 179.2 128 256 140.8 256 153.6 256 204.8 256 204.8 307.2 179.2 307.2 179.2 384 204.8 384 204.8 332.8 230.4 332.8 256 332.8 256 384 230.4 384 230.4 409.6 256 409.6 268.8 409.6 281.6 409.6 281.6 307.2 256 307.2 230.4 307.2" />
                                    <rect class="cls-1" x="307.2" y="307.2" width="25.6" height="25.6" />
                                    <rect class="cls-1" x="307.2" y="358.4" width="25.6" height="51.2" />
                                    <rect class="cls-1" x="179.2" y="409.6" width="25.6" height="51.2" />
                                    <polygon class="cls-1"
                                             points="230.4 460.8 256 460.8 256 486.4 179.2 486.4 179.2 512 256 512 281.6 512 281.6 460.8 281.6 448 281.6 435.2 230.4 435.2 230.4 460.8" />
                                    <rect class="cls-1" x="486.4" y="332.8" width="25.6" height="51.2" />
                                    <rect class="cls-1" x="358.4" y="358.4" width="25.6" height="25.6" />
                                    <polygon class="cls-1"
                                             points="486.4 409.6 460.8 409.6 460.8 460.8 473.6 460.8 486.4 460.8 512 460.8 512 435.2 486.4 435.2 486.4 409.6" />
                                    <polygon class="cls-1"
                                             points="435.2 460.8 435.2 435.2 435.2 409.6 435.2 358.4 460.8 358.4 460.8 332.8 435.2 332.8 409.6 332.8 409.6 358.4 409.6 409.6 358.4 409.6 358.4 435.2 409.6 435.2 409.6 460.8 358.4 460.8 358.4 473.6 358.4 486.4 358.4 512 384 512 384 486.4 409.6 486.4 409.6 512 460.8 512 460.8 486.4 435.2 486.4 435.2 460.8" />
                                    <rect class="cls-1" x="486.4" y="486.4" width="25.6" height="25.6" />
                                    <rect class="cls-1" x="307.2" y="435.2" width="25.6" height="25.6" />
                                    <rect class="cls-1" x="307.2" y="486.4" width="25.6" height="25.6" />
                                </svg>
                                </div>
                            </div>

                            <div v-if="searchBy === 'searchquery'">
                                <div class="mb-5 default-input flex gap-4 mx-auto">
                                    <div class="input-clear-field-wrapper flex-grow">
                                        <input type="text" name="searchquery" v-model="searchquery" readonly
                                               @click="inputActive = true"
                                               class="py-1 px-2 block w-full shadow-sm border rounded-sm  focus:outline-none focus:ring-transparent" />
                                        <div class="clear-field" v-if="searchquery" @click="clearField()">+</div>
                                    </div>
                                    <button @click="onSubmit()" class="button button-filled">{{$t('translations.components.modal-customers.search')}}</button>
                                </div>

                                <keyboard
                                    v-if="terminal.layout != 'mobileordering' && inputActive && searchBy === 'searchquery'"
                                    v-model="input"
                                    @input="changed"
                                    :layouts="[
                        '1234567890{backspace:backspace}|qwertyuiop|asdfghjkl|{shift:goto:1}zxcvbnm_,.-|{space:space}',
                        '!@#$%^&*(){backspace:backspace}|QWERTYUIOP|ASDFGHJKL|{shift:goto:0}ZXCVBNM:;|{space:space}'
                    ]"
                                    :maxlength="48"
                                ></keyboard>
                            </div>
                        </div>
                    </div>

                    <div class="mb-5 text-lg mw-pos mx-auto"
                         v-if="searched === true && customers.length === 0 && inputActive === false">
                         {{$t('translations.components.modal-customers.no-customers')}}
                    </div>

                    <div v-if="inputActive === false && searched === true" class="mw-pos mx-auto">
                        <div class="overflow-y-auto customer-list scrollbar-hide" style="height:400px">
                            <div class="relative customer-list-item" v-for="(customer, index_customer) in customers"
                                 :key="index_customer">
                                <div>
                                    <div>{{ customer.attributes.name }}</div>
                                    <div class="mt-1">{{ customer.attributes.email }}</div>
                                </div>
                                <div class="flex-grow">
                                    {{ customer.attributes.reference }}
                                </div>
                                <div>
                                    <a @click="selectCustomer(customer)" class="button button-normal">Selecteer</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                    <a @click="canPrintReport()" class="button button-wide button-large button-filled">
                        X-rapportage
                    </a>
                </div> -->
            </div>
        </div>
        <modal-customer-functions @close="$emit('close'); close()"  ref="customerfunctions" @opened="close()" />
    </div>
</template>

<script>
import axios from 'axios';

// components
import ModalClose from "./ModalClose";
import ModalCustomerFunctions from "./ModalCustomerFunctions";
import KeyboardHelper from '../mixins/keyboard.helper.js';
import BarcodeReader from "../mixins/barcode.reader";

// print

export default {
    mixins: [KeyboardHelper, BarcodeReader],
    components: {
        ModalClose,
        ModalCustomerFunctions,
    },
    data () {
        return {
            field: 'searchquery',
            active: false,
            searched: false,
            searchBy: 'searchquery',

            inputActive: true,
            searchquery: '',
            reference: '',

            customers: [],
            customer_selected: false,
        };
    },
    methods: {
        open () {
            this.active = true;
            this.$store.dispatch('terminal/setModalActive', true);
        },

        close () {
            this.active = false;
            this.$store.dispatch('terminal/setModalActive', false);
        },

        clearField () {
            this.searchquery = '';
            this.inputActive = true;
        },

        newSearch (option) {
            this.searched = false;
            this.searchquery = '';
            this.searchBy = option;
        },

        processBarcodeScan (barcode) {
            if (barcode.length == 0) {
                return;
            }

            this.reference = barcode;
            this.onSubmit();
        },

        barcodeScanned(barcode) {
            if (this.active == false) {
                return;
            }
            this.processBarcodeScan(barcode);
        },

        async onSubmit () {
            this.searched = true;
            this.inputActive = false;

            let data;
            if (this.searchBy === 'searchquery') {
                data = 'searchquery=' + this.searchquery;
            } else {
                data = 'reference=' + this.reference;
            }

            await axios.get('/customers/search?' + data).then(response => {
                this.customers = response.data.data;
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });
        },

        selectCustomer (customer) {
            this.$refs.customerfunctions.open(customer);
        },
    },
    computed: {
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
    },
};
</script>
