<template>
    <div class="w-full">
        <a @click="active = !active" class="button button-blur w-full square h-full">
            <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
            </svg>
        </a>

        <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" @click="close()">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div id="modal"
                     class="discount-modal modal-extra-large inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                     role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                     :class="{'mobile-discount': terminal.layout === 'mobileordering' || terminal.layout === 'mobiledirect', 'steps': steps}"
                >
                    <div class="modal-content">

                        <modal-close @close="close()" />

                        <div class="mb-5">
                            <div class="sm:mt-0 sm:text-left flex justify-between w-full pr-12">
                                <h3 class="modal-title text-lg leading-6 font-bold text-3xl" id="modal-headline">
                                    {{$t('translations.components.modal-discount.title')}}
                                </h3>
<!--                                <div class="flex items-center mb-4 ml-4 sfont-bold">-->
<!--                                    <div class="text-sm mr-2 capitalize">{{ discount_type }}</div>-->
<!--                                    <button @click="toggleDiscountType()" type="button"-->
<!--                                            :class="[discount_type === 'percentage' ? 'bg-sjef-purple' : 'bg-sjef-green']"-->
<!--                                            class="bg-sjef-green relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"-->
<!--                                            role="switch">-->
<!--                                                <span-->
<!--                                                    :class="[discount_type === 'fixed' ? 'translate-x-5' : 'translate-x-0']"-->
<!--                                                    class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>-->
<!--                                    </button>-->
<!--                                </div>-->
                            </div>
                        </div>

                        <div class="discount-inner">
                            <div class="wrapper" v-if="show_products" :class="{'show-products': show_products}">
                                <div class="scroller">
                                    <div
                                        id="transaction"
                                        class="list-secondary content-wrapper col-span-2 flex flex-col justify-between flex-grow">
                                        <div class="list-items overflow-y-auto scrollbar-hide">

                                            <div class="list-item grid grid-cols-12"
                                                 v-for="(line, index) in linesWithoutCashFunction" :key="index"
                                                 :class="{'selected' : line.selected, 'has-discount': line.discount_amount}"
                                                 @click="selectLine(line)">

                                                <div class="col-span-2 flex jusfity-center pr-2 items-center">
                                                    <div class="leading-5">{{
                                                            parseFloat(line.quantity)
                                                        }}
                                                    </div>
                                                </div>

                                                <div class="col-span-7 text-left flex items-center justify-start">
                                                    <div class="font-bold leading-5 truncate">{{
                                                            line.description
                                                        }}
                                                    </div>
                                                </div>

                                                <div class="col-span-3 flex items-center justify-end font-bold">
                                                    <div class="flex items-center justify-end">

                                                        <span v-if="line.discount_amount"
                                                              class="font-normal mr-4 truncate">
                                                            ({{ vueNumberFormat(line.discount_amount / (line.discount_amount + line.total) * 100) }}%
                                                            -  {{ vueNumberFormat((line.rate_without_discount - line.rate) * line.quantity) }} )
<!--                                                            <span class="sjef-green">%</span>-->
                                                        </span>

                                                        <span v-else-if="line.total !== (line.rate * line.quantity)"
                                                              class="font-normal mr-4 truncate">
                                                           ( -  {{ vueNumberFormat((line.rate * line.quantity) - line.total) }} )
                                                            <!--                                                            <span class="sjef-green">%</span>-->
                                                        </span>

                                                        <div class="flex items-center justify-end flex-wrap">
                                                            {{ vueNumberFormat(line.total) }}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div class="text-2xl font-bold text-right mt-4">
                                                {{ vueNumberFormat(transaction.total) }}
                                            </div>

                                            <div v-if="steps">
                                                <div @click="switchStep" class="button button-filled mt-2">
                                                    {{$t('translations.components.modal-discount.select-discount')}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="wrapper" v-if="show_discount" :class="{'show-discount': show_discount}">
                                <div class="scroller">
                                    <div class="discount-options">
                                        <div class="set">
                                            <h3 class=" text-lg leading-6 font-bold text-2xl mb-4">{{$t('translations.components.modal-discount.fixed-discounts')}}</h3>
                                            <div class="options">
                                                    <div v-if="discount_type === 'percentage'"
                                                        class="set-options">
                                                        <div @click="applyDiscount(discount.attributes.value)"
                                                             class="option button button-normal mr-2"
                                                             v-for="(discount, index) in discounts" :key="index">
                                                            {{ discount.attributes.value }}
                                                        </div>
                                                    </div>
                                                    <div v-if="discount_type === 'fixed'"
                                                         class="set-options">
                                                        <div @click="applyDiscount(discount.attributes.value)"
                                                             class="option button button-normal mr-2"
                                                             v-for="(discount, index) in discounts" :key="index">
                                                            {{ discount.attributes.value }}
                                                        </div>
                                                    </div>
                                                <div class="reset-buttons">
                                                    <div @click="resetDiscount()"
                                                         class="option button button-danger reset">
                                                         {{$t('translations.components.modal-discount.reset-all-discounts')}}
                                                    </div>

                                                    <div v-if="selectedLineHasDiscount" @click="applyDiscount(0)"
                                                         class="option button button-normal reset mt-4">
                                                         {{$t('translations.components.modal-discount.reset-this-discount')}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="variable">
                                                <h3 class=" text-lg leading-6 font-bold text-2xl mb-4">{{$t('translations.components.modal-discount.variable-discounts')}}</h3>
                                                <div class="variable-options">
                                                    <div class="numpad-wrapper">
                                                        <numpad :clear="clear_trigger"
                                                                @inputchanged="manual_discount = $event / 100" />
                                                    </div>

                                                    <div class="numpad-options">
                                                        <div @click="applyDiscount('manual')"
                                                             class="button button-filled button-ghost">{{$t('translations.components.modal-discount.add-discount')}}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-if="steps">
                                                    <div @click="switchStep" class="button button-filled mt-4">{{$t('translations.components.modal-discount.choose-product')}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import { setupCache } from "axios-cache-adapter";
import { mapActions } from "vuex";

const cache = setupCache({
    maxAge: 15 * 60 * 1000,
});
const axiosCache = axios.create({
    adapter: cache.adapter,
});

// components
import ModalClose from './ModalClose.vue';
import Numpad from './Numpad';

export default {
    props: ['view'],
    emits: ['selected'],
    components: {
        ModalClose,
        Numpad,
    },
    data () {
        return {
            active: false,
            clear_trigger: false,
            initialized: false,
            discount: null,
            paymenttypes: {},
            discounts: {},
            discount_type: 'percentage',
            amount: null,
            show_products: true,
            show_discount: true,
            amount_paid: '',
            balancedue: null,
            account_has_asked: false,
        };
    },

    mounted () {
        this.discountsGet();

        if (this.steps) {
            this.show_discount = false;
        }
    },

    methods: {
        toggleDiscountType () {
            if (this.discount_type === 'percentage') {
                this.discount_type = 'fixed';
            } else if (this.discount_type === 'fixed') {
                this.discount_type = 'percentage';
            }
        },

        applyDiscount (value) {
            this.clear_trigger = !this.clear_trigger;

            if (value === 'manual') {
                if (this.manual_discount) {
                    value = this.manual_discount;
                } else {
                    value = -1;
                }
            }

            console.log(value, this.discount_type);

            if ((value > 100 || value < 0) && this.discount_type === 'percentage') {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Foute invoer",
                    text: "Korting kan niet minder dan 0 of meer dan 100% zijn.",
                }, 2000);
            } else {
                let hasLineSelected = false;
                for (let i = 0; i < this.transaction.lines.length; i++ ) {
                    if (this.transaction.lines[i].selected === true){
                        hasLineSelected = true;
                        break;
                    }
                }

                if (hasLineSelected === false) {
                    this.$store.dispatch('transaction/selectAllLines');
                }

                if (this.discount_type === 'percentage') {
                    this.$store.dispatch('transaction/lineSelectedDiscountPercentage', parseFloat(value));
                } else {
                    this.$store.dispatch('transaction/lineSelectedDiscountFixed', parseFloat(value));
                }

                this.$store.dispatch('transaction/unselectAllLines', parseFloat(value));

                if (value === 0) {
                    this.$notify({ group: "notifications", type: 'success', title: "Korting verwijderd" }, 2000);
                } else {
                    this.$notify({ group: "notifications", type: 'success', title: "Korting toegevoegd" }, 2000);
                }

                if (this.steps) {
                    this.show_discount = false;
                    this.show_products = true;
                }
            }
        },

        selectLine (line) {
            this.$store.dispatch('transaction/multipleLineSelect', line);
        },

        resetDiscount () {
            this.$store.dispatch('transaction/resetAllDiscount');
        },

        close () {
            this.active = false;
        },

        ...mapActions({
            lineSelect: 'transaction/multipleLineSelect',
        }),

        async amountGet () {

            if (this.$store.getters['transaction/type'] == 'cash_inout') {
                this.amount = this.$route.params.amount;
                this.balancedue = this.$route.params.amount;
                this.amount_paid = 0;
                return;
            }

            await axios.get('/transactions/' + this.$store.getters['transaction/id'] + '/amounts').then(response => {
                this.amount = response.data.balancedue;
                this.balancedue = response.data.balancedue;
                this.amount_paid = response.data.amount_paid;
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });
        },

        classExtraction (item) {

            if (item.attributes.name === 'PIN' || item.attributes.name === 'Cash') {
                return 'button-filled';
            }

            return 'button-ghost';
        },

        async discountApply (discount) {

            await axios.post('/transactions/' + this.transaction.id + '/discount', {
                discount_id: discount.id,
            }).then(response => {
                this.discount = discount;
                this.transaction = response.data.data;

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });

            await this.amountGet();
        },

        async discountClear () {

            await axios.post('/transactions/' + this.transaction.id + '/discount/clear', {}).then(response => {
                this.discount = null;
                this.transaction = response.data.data;

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });

            await this.amountGet();
        },

        async discountsGet () {

            let dataDiscounts = JSON.parse(localStorage.getItem('masterdata.discounts'));

            if (!dataDiscounts) {
                try {
                    const response = await axios({
                        url: '/discounts',
                        method: 'get',
                    });

                    dataDiscounts = response.data.data;
                    localStorage.setItem('masterdata.discounts', JSON.stringify(dataDiscounts));

                } catch (error) {
                    localStorage.removeItem('masterdata.discounts');
                    console.error(error);
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Fout opgetreden",
                        text: "Oeps er ging iets fout..",
                    }, 2000);
                }
            }
            this.discounts = dataDiscounts;
        },


        async transactionGet () {

            axios.get('/transactions/' + this.$store.getters['transaction/id']).then(response => {
                this.transaction = response.data.data;
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            })
                .finally(() => {
                    this.initialized = true;
                });
        },

        async transactionInit () {
            axios.post('/transactions', this.$store.getters['transaction/transaction']).then(response => {
                // set transaction
                this.transaction = response.data.data;

                // set transaction_id
                this.$store.dispatch('transaction/setId', this.transaction.id);

                // set the amounts
                this.amountGet().then(() => {
                    // preferred paymenttype used
                    if (this.$route.params.paymenttype_id > 0) {
                        this.paymentGoto(this.$route.params.paymenttype_id);
                        return;
                    }

                    // get the discounts
                    this.discountsGet();

                    // get the paymenttypes
                    this.paymenttypesGet();
                    this.initialized = true;
                });

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => {
            });
        },

        async transactionSetAccount (data) {
            let account = data.account;
            let paymenttype = data.paymenttype;

            await axios.patch('/transactions/' + this.transaction.id + '/account', {
                account_id: account.id,
            }).then(async () => {
                // set transaction
                this.transaction.attributes.account_id = account.id;

                // set transaction_id
                this.$store.dispatch('transaction/setAccount', account);

                // apply discount
                if (account.relationships.discount) {
                    await this.discountApply(account.relationships.discount);
                }

                // continue to payment
                if (paymenttype) {
                    this.paymentGoto(paymenttype);
                }

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => {
            });
        },

        async transactionSetBooking (booking) {
            axios.patch('/transactions/' + this.transaction.id + '/booking', {
                booking_id: booking.id,
            }).then(() => {
                // set transaction
                this.transaction.attributes.booking_id = booking.id;

                // set transaction_id
                this.$store.dispatch('transaction/setBooking', booking);

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => {
            });
        },

        async transactionSetWallettoken (data) {

            // set the amount paid with the wallet
            this.amount = data.amount;
            this.$store.dispatch('payment/setAmount', this.amount);

            this.paymentGoto(data.paymenttype);
            return;
        },

        switchStep () {

            if (this.steps) {
                this.show_products = !this.show_products;
                this.show_discount = !this.show_discount;
            } else {
                this.show_products = true;
                this.show_discount = true;
            }
        },
    },

    computed: {
        steps () {
            if (this.terminal.layout === 'mobiledirect' || this.terminal.layout === 'mobileordering') {
                return true;
            }

            return false;
        },


        selectedLineHasDiscount () {
            let lines = this.transaction.lines;
            let selectedLinesWithDiscount = [];

            lines.forEach(function (line) {
                if (line.selected && line.discount_amount > 0) {
                    selectedLinesWithDiscount.push(line);
                }
            });

            if (selectedLinesWithDiscount.length > 0) {
                return true;
            }

            return false;
        },

        linesWithoutCashFunction () {
            return this.transaction.lines.filter(i => i.type !== 'cashinout');
        },

        terminal () {
            return this.$store.getters['terminal/terminal'];
        },

        transaction () {
            return this.$store.getters['transaction/transaction'];
        },
    },
};
</script>
